import React, { useState, useEffect } from "react";

import Menu from "../components/Menu";
import Home from "../components/sections/Home";
import About from "../components/sections/About";
import Creations from "../components/sections/Creations";
import Contact from "../components/sections/Contact";
import { LOCALES } from "../i18n";

import "../fonts/font.css";
export default function Portfolio() {
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  });
  function handleWindowSizeChange() {
    if (window.innerWidth <= 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }

  function navigatorLanguage() {
    var lang = navigator.language.substr(0, 2).toUpperCase();
    if (lang === "FR") {
      return LOCALES.FRENCH;
    } else {
      return LOCALES.ENGLISH;
    }
  }

  const [locale, setLocale] = useState(navigatorLanguage());

  const LocaleSetter = (lang) => {
    setLocale(lang);
  };

  return (
    <div id="main-app">
      <Menu isMobile={isMobile} locale={locale} setLocale={LocaleSetter} />
      <main>
        <Home locale={locale} setLocale={LocaleSetter} />
        <About isMobile={isMobile} locale={locale} setLocale={LocaleSetter} />
        <Creations
          isMobile={isMobile}
          locale={locale}
          setLocale={LocaleSetter}
        />
        <Contact locale={locale} setLocale={LocaleSetter} />
      </main>
    </div>
  );
}
