import React, { useEffect } from "react";

import gsap from "gsap";
import "./sections.css";
import { I18nProvider } from "../../i18n";

import translate from "../../i18n/translate";

import CourbeAboutSVG from "../svg/CourbeAbout";
import ScrollIndicator from "../svg/ScrollIndicator";
import "./About.css";

export default function About({ isMobile, locale }) {
  function calculAge(dateold) {
    var today = new Date();
    var birthday = new Date("2 September 2001");
    var mnew = today.getMonth();
    var dnew = today.getDate();
    var mold = birthday.getMonth();
    var dold = birthday.getDate();
    var diff = ((today.getTime() - birthday.getTime()) / 31536000000).toFixed(
      0
    );
    if (mold > mnew) diff--;
    else {
      if (mold === mnew) {
        if (dold > dnew) diff--;
      }
    }
    var age = diff;
    return age;
  }

  const settings = {
    // 0 = Aucune recherche, je suis embauché, 1 = Recherche de travail, 2 = Recherche de Mission
    SearchingFor: 1,
    entreprise: "",
  };

  useEffect(() => {
    gsap.timeline({}).to("h3", {
      translateX: 0,
      ease: "Back.easeInOut",
      duration: 1.3,
    });
  });

  return (
    <div id="about" className="section">
      <h3>
        <span id="hello">
          <I18nProvider locale={locale}>{translate("aboutHello")}</I18nProvider>
        </span>
        <br />
        {settings.SearchingFor === 0 ? (
          <span>
            <I18nProvider locale={locale}>
              {translate("aboutDesc0.1", { age: calculAge() })}
            </I18nProvider>
            <br />
            <I18nProvider locale={locale}>
              {translate("aboutDesc0.2")}
            </I18nProvider>
          </span>
        ) : settings.SearchingFor === 1 ? (
          <span>
            <I18nProvider locale={locale}>
              {translate("aboutDesc1.1", { age: calculAge() })}
            </I18nProvider>
            <br />
            <I18nProvider locale={locale}>
              {translate("aboutDesc1.2")}
            </I18nProvider>
          </span>
        ) : (
          <span>
            <I18nProvider locale={locale}>
              {translate("aboutDesc2.1", { age: calculAge() })}
            </I18nProvider>
            <br />
            <I18nProvider locale={locale}>
              {translate("aboutDesc2.2")}
            </I18nProvider>
          </span>
        )}
      </h3>
      <CourbeAboutSVG isMobile={isMobile} locale={locale} />
      <ScrollIndicator />
    </div>
  );
}
