import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";

import { I18nProvider } from "../i18n";
import translate from "../i18n/translate";

const useStyles = (isMobile) =>
  makeStyles(() => ({
    blueCard: {
      width: "100vw",
      height: "100vh",
      background:
        "linear-gradient(90deg, rgba(102,202,183,1) 0%, rgba(65,148,147,1) 100%)",
    },
    card: {
      width: "90vmin",
      height: "93vh",
      marginTop: "8vh",
      margin: "auto",
      backgroundColor: "#f8f8f8",
      overflow: "auto",
      boxShadow: "0px 0px 50px 40px rgba(0,0,0,0.20)",
      "&::-webkit-scrollbar-track": {
        "&-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
        backgroundColor: "#fcfafa",
      },

      "&::-webkit-scrollbar": {
        width: "6px",
        backgroundColor: "#fcfafa",
      },

      "&::-webkit-scrollbar-thumb": {
        "&-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
        backgroundColor: "#c7c3c3",
      },
    },
    menu: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: "25px",
    },
    title: {
      width: "100%",
      textAlign: "center",
      fontFamily: "Bahnschrift Regular",
      fontSize: "2vmin",
      color: "#0f0f0f",
    },
    closeIcon: {
      position: "sticky",
      top: "30px",
      left: "90%",
    },
    separator: {
      height: "2px",
      width: "90px",
      backgroundColor: "black",
      display: "grid",
      margin: "auto",
    },
    grid: {
      marginTop: "8%",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: '"a b" "c c" "d d" "e e" "f f"',
    },
    marginBotton: {
      marginBottom: "1rem",
    },
    contact: {
      marginLeft: "25%",
    },
    credit: {
      gridArea: "c",
      margin: "4% 12% 0% 12%",
    },
    publications: {
      gridArea: "d",
      margin: "4% 12% 0% 12%",
    },
    link: {
      gridArea: "e",
      margin: "4% 12% 0% 12%",
    },
    cookies: {
      gridArea: "f",
      margin: "4% 12% 15% 12%",
    },
    scroll: {},
  }));

export default function LegalMentions({
  isMobile,
  locale,
  openLegal,
  handleCloseLegal,
}) {
  const classes = useStyles(isMobile)();

  return (
    <Modal
      className={classes.modal}
      open={openLegal}
      onClose={handleCloseLegal}
    >
      <Fade timeout={400} in={openLegal}>
        <Card className={classes.blueCard}>
          <CardContent>
            <Card className={classes.card}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={handleCloseLegal}
              />
              <div className={classes.menu}>
                <Typography
                  className={classes.title}
                  gutterBottom
                  variant="h4"
                  component="h2"
                >
                  <I18nProvider locale={locale}>
                    {translate("contactLegal")}
                  </I18nProvider>
                </Typography>
              </div>
              <span className={classes.separator}></span>
              <div className={classes.grid}>
                <div className={classes.contact} id="owner">
                  <Typography
                    className={classes.marginBotton}
                    gutterBottom
                    variant="h7"
                    component="h4"
                  >
                    <I18nProvider locale={locale}>
                      {translate("legalOwner")}
                    </I18nProvider>
                  </Typography>
                  <Typography variant="body1" component="p">
                    <I18nProvider locale={locale}>
                      {translate("leo")}
                    </I18nProvider>
                  </Typography>
                  <Typography variant="body1" component="p">
                    <I18nProvider locale={locale}>
                      {translate("address")}
                    </I18nProvider>
                  </Typography>
                  <Typography variant="body1" component="p">
                    <I18nProvider locale={locale}>
                      {translate("ZIPCode")}
                    </I18nProvider>
                  </Typography>
                  <Typography variant="body1" component="p">
                    <I18nProvider locale={locale}>
                      {translate("email")}
                    </I18nProvider>
                  </Typography>
                </div>
                <div id="host">
                  <div className={classes.contact} id="host">
                    <Typography
                      className={classes.marginBotton}
                      variant="h7"
                      component="h4"
                    >
                      <I18nProvider locale={locale}>
                        {translate("legalHost")}
                      </I18nProvider>
                    </Typography>
                    <Typography variant="body1" component="p">
                      <I18nProvider locale={locale}>
                        {translate("ovh")}
                      </I18nProvider>
                    </Typography>
                    <Typography variant="body1" component="p">
                      <I18nProvider locale={locale}>
                        {translate("addressOVH")}
                      </I18nProvider>
                    </Typography>
                    <Typography variant="body1" component="p">
                      <I18nProvider locale={locale}>
                        {translate("ZIPCodeOVH")}
                      </I18nProvider>
                    </Typography>
                    <Typography variant="body1" component="p">
                      <I18nProvider locale={locale}>
                        {translate("linkOVH")}
                      </I18nProvider>
                    </Typography>
                  </div>
                </div>
                <div className={classes.credit}>
                  <Typography
                    variant="h7"
                    component="h4"
                    gutterBottom
                    className={classes.marginBotton}
                  >
                    <I18nProvider locale={locale}>
                      {translate("legalCredit")}
                    </I18nProvider>
                  </Typography>
                  <Typography variant="body1" component="p">
                    <I18nProvider locale={locale}>
                      {translate("legalCreditBody")}
                    </I18nProvider>
                    <a
                      href="https://www.instagram.com/ultioo_____/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      @Ultio
                    </a>
                    <I18nProvider locale={locale}>
                      {translate("legalCreditBody2")}
                    </I18nProvider>
                    <a
                      href="https://www.picuki.com/profile/romain_huet"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Romain Huet
                    </a>
                    <I18nProvider locale={locale}>
                      {translate("legalCreditBody3")}
                    </I18nProvider>
                    <a
                      href="https://www.iconfinder.com/social-media-icons"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Iconfider.com
                    </a>
                    <I18nProvider locale={locale}>
                      {translate("legalCreditBody4")}
                    </I18nProvider>
                  </Typography>
                </div>
                <div className={classes.publications}>
                  <Typography
                    className={classes.marginBotton}
                    gutterBottom
                    variant="h7"
                    component="h4"
                  >
                    <I18nProvider locale={locale}>
                      {translate("legalPublication")}
                    </I18nProvider>
                  </Typography>
                  <Typography
                    className={classes.marginBotton}
                    gutterBottom
                    variant="body1"
                    component="p"
                  >
                    <I18nProvider locale={locale}>
                      {translate("legalPublicationBody")}
                    </I18nProvider>
                  </Typography>
                </div>
                <div className={classes.link}>
                  <Typography
                    className={classes.marginBotton}
                    gutterBottom
                    variant="h7"
                    component="h4"
                  >
                    <I18nProvider locale={locale}>
                      {translate("legalLink")}
                    </I18nProvider>
                  </Typography>
                  <Typography
                    className={classes.marginBotton}
                    gutterBottom
                    variant="body1"
                    component="p"
                  >
                    <I18nProvider locale={locale}>
                      {translate("legalLinkBody")}
                    </I18nProvider>
                  </Typography>
                </div>
                <div className={classes.cookies}>
                  <Typography
                    className={classes.marginBotton}
                    gutterBottom
                    variant="h7"
                    component="h4"
                  >
                    <I18nProvider locale={locale}>
                      {translate("legalCookies")}
                    </I18nProvider>
                  </Typography>
                  <Typography
                    className={classes.marginBotton}
                    gutterBottom
                    variant="body1"
                    component="p"
                  >
                    <I18nProvider locale={locale}>
                      {translate("legalCookiesBody")}
                    </I18nProvider>
                  </Typography>
                </div>
              </div>
            </Card>
          </CardContent>
        </Card>
      </Fade>
    </Modal>
  );
}
