import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import * as PIXI from "pixi.js";
import "../svg/CourbeAbout.css";

import { I18nProvider } from "../../i18n";
import translate from "../../i18n/translate";

const useStyles = (isMobile) =>
  makeStyles(() => ({
    card: {
      width: "94vw",
      height: "94vh",
      backgroundColor: "#f0f2f5",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "3%",
    },
    closeIcon: {
      position: "fixed",
      top: "4vh",
      right: "3vw",
      cursor: "pointer",
      fontSize: "3.5vmin",
    },
  }));

export default function FlappyBird({
  isMobile,
  locale,
  creationOpened,
  handleCloseCreationOpened,
}) {
  useEffect(() => {
    /*------------------------------------------------------VARIABLE SA MERE-----------------------------------------------------------------------------*/

    var wST = 504,
      hST = 671;
    var vx = 3,
      angle = 0.0,
      amplitude = 180;
    var ground, pipes, ready, bird, gameOver;
    var phaseIntro = true;
    var vY = 0.0,
      impulsion = -10,
      accY = 0.5;
    var vie = 5;
    var yPipe;
    var b1, b2;
    var nbtPoints = 0;
    var phaseJeu = "jeu";
    /*------------------------------------------------------APPLICATION-----------------------------------------------------------------------------*/

    let app = new PIXI.Application({
      width: wST, // default: 800
      height: hST, // default: 600
      antialias: true, // default: false
      transparent: false, // default: false
      backgroundColor: 0x706e69, // defaut : 0x000000
      resolution: 1, // default: 1
    });
    let GO, Pt, bg;

    document.body.appendChild(app.view);

    /*------------------------------------------------------CHARGEMENT-----------------------------------------------------------------------------*/

    const loader = PIXI.Loader.shared;
    PIXI.utils.clearTextureCache();
    loader.add("spritesheet", "assets/flappy_bird.json");

    loader.load((loader, resources) => {
      createBG();
      createGround();
      createPipes();
      createReady();
      createBird();
      createPoint();

      app.ticker.add((delta) => gameLoop());
    });

    loader.onProgress.add(() => {}); // called once per loaded/errored file
    loader.onError.add(() => {}); // called once per errored file
    loader.onLoad.add(() => {}); // called once per loaded file
    loader.onComplete.add(() => {}); // called once when the queued resources all load.

    /*------------------------------------------------------CREATION-----------------------------------------------------------------------------*/

    function createBG() {
      bg = new PIXI.Sprite(PIXI.Texture.from("background.png"));
      app.stage.addChild(bg);
    }
    function createGround() {
      ground = new PIXI.Sprite(PIXI.Texture.from("ground.png"));
      app.stage.addChild(ground);
      ground.x = 0;
      ground.y = hST - ground.height * 0.6;
    }
    function createPipes() {
      pipes = new PIXI.Sprite(PIXI.Texture.from("pipe.png"));
      app.stage.addChild(pipes);
      pipes.anchor.set(0.5);
      pipes.y = hST / 2;
      pipes.x = wST + pipes.width * 0.5;

      b1 = new PIXI.Graphics();
      b1.beginFill(0, 0);
      b1.drawRect(0, 0, 91, 485);
      b1.x = -46;
      b1.y = -572;
      pipes.addChild(b1);

      b2 = new PIXI.Graphics();
      b2.beginFill(0, 0);
      b2.drawRect(0, 0, 91, 485);
      b2.x = -46;
      b2.y = 86;
      pipes.addChild(b2);
    }
    function createReady() {
      ready = new PIXI.Sprite(PIXI.Texture.from("get_ready.png"));
      app.stage.addChild(ready);
      ready.anchor.set(0.5);
      ready.x = wST * 0.5;
      ready.y = hST / 2;
    }
    function createBird() {
      var t = [
        PIXI.Texture.from("bird0.png"),
        PIXI.Texture.from("bird1.png"),
        PIXI.Texture.from("bird2.png"),
        PIXI.Texture.from("bird3.png"),
      ];

      bird = new PIXI.AnimatedSprite(t);
      app.stage.addChild(bird);
      bird.anchor.set(0.5);
      bird.x = wST * 0.3;
      bird.y = hST / 2;
      bird.play();
      bird.animationSpeed = 0.3;
    }
    function createGO() {
      GO = new PIXI.Sprite(PIXI.Texture.from("game_over.png"));
      app.stage.addChild(GO);
      GO.anchor.set(0.5);
      GO.x = wST / 2;
      GO.y = hST / 2;
      vY = 0;
    }
    function createPoint() {
      Pt = new PIXI.Text("Points : " + nbtPoints, {
        fontFamily: "Arial",
        fontSize: 35,
        fill: 0xffffff,
        align: "center",
      });
      app.stage.addChild(Pt);
    }
    /*------------------------------------------------------EVENEMENT-----------------------------------------------------------------------------*/

    document.addEventListener("keydown", onKeyDown);

    function onKeyDown(e) {
      if (e.keyCode === 32) {
        phaseIntro = false;
        app.stage.removeChild(ready);
        vY = impulsion;
      }
    }

    /*------------------------------------------------------ANIMATION-----------------------------------------------------------------------------*/

    function gameLoop() {
      if (phaseJeu === "GO") {
      } else {
        ground.x -= vx;
        if (ground.x <= -121) {
          ground.x = 0;
        }
        if (phaseIntro === true) {
          bird.y = hST / 2 + Math.sin(angle) * amplitude;
          angle += 0.08;
        } else {
          pipes.x -= vx;
          if (pipes.x < 0 - pipes.width / 2) {
            pipes.x = wST + pipes.width / 2;
            pipes.y = hST / 2 + rdm(-235, 235);
          }
          bird.y += vY;
          vY = Math.min(10, vY + accY);
          bird.rotation = vY * 0.04;
          if (bird.y < bird.height / 2) {
            vY = 0;
            bird.rotation = 0.0;
            bird.y = bird.height / 2 + 2;
          }

          if (bird.y > ground.y || collide(bird, b1) || collide(bird, b2)) {
            bird.y = hST / 2;
            vie--;
            pipes.x = wST;
            if (vie <= 0) {
              createGO();
              app.stage.removeChild(bird);
              phaseJeu = "GO";
            }
          }
        }
        if (wST * 0.3 - 2 < pipes.x && pipes.x < wST * 0.3 + 2) {
          nbtPoints++;
          app.stage.removeChild(Pt);
          createPoint();
        }
      }
    }
    /*------------------------------------------------------UTILITAIRE-----------------------------------------------------------------------------*/

    function rdm(v1, v2) {
      return v1 + Math.random() * (v2 - v1);
    }

    function collide(r1, r2) {
      var bb1 = r1.getBounds();
      var bb2 = r2.getBounds();

      var c1 = bb1.x + bb1.width < bb2.x;
      var c2 = bb2.x + bb2.width < bb1.x;
      var c3 = bb1.y + bb1.height < bb2.y;
      var c4 = bb2.y + bb2.height < bb1.y;

      return !(c1 || c2 || c3 || c4);
    }
  });

  const classes = useStyles(isMobile)();

  return (
    <div>
      <Modal open={creationOpened} onClose={handleCloseCreationOpened}>
        <Card className={classes.card}>
          <CloseIcon
            className={classes.closeIcon}
            onClick={handleCloseCreationOpened}
          >
            X
          </CloseIcon>
          <Typography gutterBottom variant="h3" component="h3">
            <I18nProvider locale={locale}>
              {translate("creationTitleGeometry")}
            </I18nProvider>
          </Typography>
          <div id="flappyBird"></div>
          <Typography gutterBottom variant="body1" component="p">
            <I18nProvider locale={locale}>{translate("test")}</I18nProvider>
          </Typography>
          <Typography gutterBottom variant="body2" component="p">
            <I18nProvider locale={locale}>{translate("tes")}</I18nProvider>
          </Typography>
        </Card>
      </Modal>
    </div>
  );
}
