import React from "react";

export default function ScrollIndicator() {
  return (
    <svg viewBox="0 0 195.53 125.17" id="scollIndicator">
      <path
        id="arrowDownIndicator"
        d="M94.84,151c0,.27.17.42.3.56q2.7,2.86,5.42,5.71l.17.17c.51.21.42-.33.63-.5a36.67,36.67,0,0,0,3.37-3.46l1.06-1.16.15-.17a6,6,0,0,0,1.11-1.19h0l.79-.84,2.76-3,2-2.19c.5-.45.9-1.06,1.63-1.2a2,2,0,0,1,2.1,1.23,2,2,0,0,1-.1,1.17c-.05.35-.36.55-.51.85l-.3.33-.9,1L113,150a3.48,3.48,0,0,0-.74.79,1.65,1.65,0,0,0-.51.54l-1.93,2.08-5.25,5.67L102,161.89l-1.08.34-1-.28-.25-.07a3.32,3.32,0,0,0-1-1.11l-.11-.12a7,7,0,0,0-1.11-1.2l-.16-.17-1-1.12-.31-.32a2.94,2.94,0,0,0-.79-.86l-.3-.32-.72-.78-.63-.69c-.49-.55-1-1.12-1.49-1.65a31.11,31.11,0,0,1-2.15-2.29c-1.11-1.34-2.37-2.55-3.56-3.82a6.29,6.29,0,0,1-1-1.37l0-1.06q1.3-2.2,3.33-.42a10.11,10.11,0,0,0,1.54,1.66l.9,1,.19.23a4.36,4.36,0,0,0,.89.93,10.17,10.17,0,0,0,1.41,1.52c.37.39.63.9,1.17,1.13Z"
        transform="translate(-3.22 -40.06)"
      />
      <path
        className="ScrollLowOpacity"
        d="M44.19,92.66l-1.83.62a15.16,15.16,0,0,1-3.63.76,14.94,14.94,0,0,1-3.65-.16l.59-4a11.51,11.51,0,0,0,4.26,0,12.48,12.48,0,0,0,4.1-1.6,8.68,8.68,0,0,0,3.62-3.51A3.74,3.74,0,0,0,47.46,81v0a3.51,3.51,0,0,0-1.92-1.67,4.83,4.83,0,0,0-2.43-.1,24.14,24.14,0,0,0-3.18,1l-.12,0a.31.31,0,0,1-.12,0l-.29.11a31.8,31.8,0,0,1-4.67,1.45,6.68,6.68,0,0,1-3.87-.38,7.62,7.62,0,0,1-3.55-3.42v0A8.48,8.48,0,0,1,26,73.47a7.57,7.57,0,0,1,1.55-4.3,15.13,15.13,0,0,1,7.11-5,15.46,15.46,0,0,1,3.08-.66,17.8,17.8,0,0,1,3.35,0l-.34,4a13.88,13.88,0,0,0-3.79.12,9.45,9.45,0,0,0-3.16,1.17,7.74,7.74,0,0,0-3.37,3.39,3.92,3.92,0,0,0,.3,3.74v0a3.25,3.25,0,0,0,1.94,1.6,5.28,5.28,0,0,0,2.47,0,31.8,31.8,0,0,0,3.47-1.08.53.53,0,0,0,.15,0l.14,0,.21-.08.21-.07A24.54,24.54,0,0,1,43.76,75a6.88,6.88,0,0,1,3.77.55,7.53,7.53,0,0,1,3.33,3.27l0,0a8,8,0,0,1,1.24,4.56,7.82,7.82,0,0,1-1.67,4.35A15,15,0,0,1,46,91.59Z"
        transform="translate(-4.35 -40.06)"
      />
      <path
        className="ScrollLowOpacity"
        d="M64.56,82.89,62,82.3a9.21,9.21,0,0,1-4.54-2.54A12.41,12.41,0,0,1,54.5,74.7l-2.42-7.61a12.47,12.47,0,0,1-.53-5.82,9.24,9.24,0,0,1,2.23-4.71,10.33,10.33,0,0,1,4.66-2.9,10,10,0,0,1,4.71-.38,9.79,9.79,0,0,1,4.27,1.83,11.4,11.4,0,0,1,3.2,3.75l-4,1.27a6.52,6.52,0,0,0-1.95-1.93,6.11,6.11,0,0,0-2.49-.94,5.9,5.9,0,0,0-5.26,2,5.75,5.75,0,0,0-1.25,2.93,8.21,8.21,0,0,0,.39,3.67l2.42,7.61a8.2,8.2,0,0,0,1.8,3.21A5.64,5.64,0,0,0,63,78.31a5.72,5.72,0,0,0,3.24-.14,5.91,5.91,0,0,0,2.21-1.27A5.79,5.79,0,0,0,70,74.75,6.31,6.31,0,0,0,70.39,72l4-1.27a11.44,11.44,0,0,1-.45,4.91A9.81,9.81,0,0,1,67.44,82Z"
        transform="translate(-4.35 -40.06)"
      />
      <path
        className="ScrollLowOpacity"
        d="M78.51,49l4.07-.32,2.23,29.05L80.74,78Zm2.76,12.75,9.9-.76a3.75,3.75,0,0,0,2-.71,3.63,3.63,0,0,0,1.26-1.7,6,6,0,0,0-.36-4.73,3.74,3.74,0,0,0-1.5-1.48,3.87,3.87,0,0,0-2.11-.4l-9.9.76-.3-3.87L90,48.1a9.15,9.15,0,0,1,4.5.7,7.25,7.25,0,0,1,3.18,2.72,9,9,0,0,1,1.39,4.32,8.88,8.88,0,0,1-.72,4.48,7.34,7.34,0,0,1-2.74,3.17,9.12,9.12,0,0,1-4.32,1.38l-9.74.75Zm8.14,2.37,4-1.23,8.5,13.53-4.83.37Z"
        transform="translate(-4.35 -40.06)"
      />
      <path
        className="ScrollLowOpacity"
        d="M109.58,76.24a9.13,9.13,0,0,1-3.09-4.18,12.06,12.06,0,0,1-.56-5.75l1-8.18a12.13,12.13,0,0,1,2-5.44,9,9,0,0,1,4-3.28,11.47,11.47,0,0,1,10.64,1.34,9.08,9.08,0,0,1,3.1,4.18,12.06,12.06,0,0,1,.55,5.75l-1,8.19a12.13,12.13,0,0,1-2,5.43,9.08,9.08,0,0,1-4,3.28,11.49,11.49,0,0,1-10.65-1.34Zm8.91-2.41a5.39,5.39,0,0,0,2.4-2,7.51,7.51,0,0,0,1.17-3.33L123.13,60a7.66,7.66,0,0,0-.31-3.52A5.5,5.5,0,0,0,121,54a6.62,6.62,0,0,0-6.3-.79,5.42,5.42,0,0,0-2.39,2,7.64,7.64,0,0,0-1.18,3.33L110.07,67a7.53,7.53,0,0,0,.31,3.52A5.43,5.43,0,0,0,112.2,73a6.59,6.59,0,0,0,6.29.79Z"
        transform="translate(-4.35 -40.06)"
      />
      <path
        className="ScrollLowOpacity"
        d="M139,52.9l3.87,1.31L133.5,81.78l-3.87-1.31Zm-6.16,24.55,15.78,5.34-1.25,3.68-15.77-5.34Z"
        transform="translate(-4.35 -40.06)"
      />
      <path
        className="ScrollLowOpacity"
        d="M165,63,168.57,65,153.91,90.17l-3.53-2ZM154.1,85.79l14.39,8.39-2,3.35-14.39-8.38Z"
        transform="translate(-4.35 -40.06)"
      />
      <clipPath id="clipScroll">
        <path
          d="M44.19,92.66l-1.83.62a15.16,15.16,0,0,1-3.63.76,14.94,14.94,0,0,1-3.65-.16l.59-4a11.51,11.51,0,0,0,4.26,0,12.48,12.48,0,0,0,4.1-1.6,8.68,8.68,0,0,0,3.62-3.51A3.74,3.74,0,0,0,47.46,81v0a3.51,3.51,0,0,0-1.92-1.67,4.83,4.83,0,0,0-2.43-.1,24.14,24.14,0,0,0-3.18,1l-.12,0a.31.31,0,0,1-.12,0l-.29.11a31.8,31.8,0,0,1-4.67,1.45,6.68,6.68,0,0,1-3.87-.38,7.62,7.62,0,0,1-3.55-3.42v0A8.48,8.48,0,0,1,26,73.47a7.57,7.57,0,0,1,1.55-4.3,15.13,15.13,0,0,1,7.11-5,15.46,15.46,0,0,1,3.08-.66,17.8,17.8,0,0,1,3.35,0l-.34,4a13.88,13.88,0,0,0-3.79.12,9.45,9.45,0,0,0-3.16,1.17,7.74,7.74,0,0,0-3.37,3.39,3.92,3.92,0,0,0,.3,3.74v0a3.25,3.25,0,0,0,1.94,1.6,5.28,5.28,0,0,0,2.47,0,31.8,31.8,0,0,0,3.47-1.08.53.53,0,0,0,.15,0l.14,0,.21-.08.21-.07A24.54,24.54,0,0,1,43.76,75a6.88,6.88,0,0,1,3.77.55,7.53,7.53,0,0,1,3.33,3.27l0,0a8,8,0,0,1,1.24,4.56,7.82,7.82,0,0,1-1.67,4.35A15,15,0,0,1,46,91.59Z"
          transform="translate(-4.35 -40.06)"
        />
        <path
          d="M64.56,82.89,62,82.3a9.21,9.21,0,0,1-4.54-2.54A12.41,12.41,0,0,1,54.5,74.7l-2.42-7.61a12.47,12.47,0,0,1-.53-5.82,9.24,9.24,0,0,1,2.23-4.71,10.33,10.33,0,0,1,4.66-2.9,10,10,0,0,1,4.71-.38,9.79,9.79,0,0,1,4.27,1.83,11.4,11.4,0,0,1,3.2,3.75l-4,1.27a6.52,6.52,0,0,0-1.95-1.93,6.11,6.11,0,0,0-2.49-.94,5.9,5.9,0,0,0-5.26,2,5.75,5.75,0,0,0-1.25,2.93,8.21,8.21,0,0,0,.39,3.67l2.42,7.61a8.2,8.2,0,0,0,1.8,3.21A5.64,5.64,0,0,0,63,78.31a5.72,5.72,0,0,0,3.24-.14,5.91,5.91,0,0,0,2.21-1.27A5.79,5.79,0,0,0,70,74.75,6.31,6.31,0,0,0,70.39,72l4-1.27a11.44,11.44,0,0,1-.45,4.91A9.81,9.81,0,0,1,67.44,82Z"
          transform="translate(-4.35 -40.06)"
        />
        <path
          d="M78.51,49l4.07-.32,2.23,29.05L80.74,78Zm2.76,12.75,9.9-.76a3.75,3.75,0,0,0,2-.71,3.63,3.63,0,0,0,1.26-1.7,6,6,0,0,0-.36-4.73,3.74,3.74,0,0,0-1.5-1.48,3.87,3.87,0,0,0-2.11-.4l-9.9.76-.3-3.87L90,48.1a9.15,9.15,0,0,1,4.5.7,7.25,7.25,0,0,1,3.18,2.72,9,9,0,0,1,1.39,4.32,8.88,8.88,0,0,1-.72,4.48,7.34,7.34,0,0,1-2.74,3.17,9.12,9.12,0,0,1-4.32,1.38l-9.74.75Zm8.14,2.37,4-1.23,8.5,13.53-4.83.37Z"
          transform="translate(-4.35 -40.06)"
        />
        <path
          d="M109.58,76.24a9.13,9.13,0,0,1-3.09-4.18,12.06,12.06,0,0,1-.56-5.75l1-8.18a12.13,12.13,0,0,1,2-5.44,9,9,0,0,1,4-3.28,11.47,11.47,0,0,1,10.64,1.34,9.08,9.08,0,0,1,3.1,4.18,12.06,12.06,0,0,1,.55,5.75l-1,8.19a12.13,12.13,0,0,1-2,5.43,9.08,9.08,0,0,1-4,3.28,11.49,11.49,0,0,1-10.65-1.34Zm8.91-2.41a5.39,5.39,0,0,0,2.4-2,7.51,7.51,0,0,0,1.17-3.33L123.13,60a7.66,7.66,0,0,0-.31-3.52A5.5,5.5,0,0,0,121,54a6.62,6.62,0,0,0-6.3-.79,5.42,5.42,0,0,0-2.39,2,7.64,7.64,0,0,0-1.18,3.33L110.07,67a7.53,7.53,0,0,0,.31,3.52A5.43,5.43,0,0,0,112.2,73a6.59,6.59,0,0,0,6.29.79Z"
          transform="translate(-4.35 -40.06)"
        />
        <path
          d="M139,52.9l3.87,1.31L133.5,81.78l-3.87-1.31Zm-6.16,24.55,15.78,5.34-1.25,3.68-15.77-5.34Z"
          transform="translate(-4.35 -40.06)"
        />
        <path
          d="M165,63,168.57,65,153.91,90.17l-3.53-2ZM154.1,85.79l14.39,8.39-2,3.35-14.39-8.38Z"
          transform="translate(-4.35 -40.06)"
        />
      </clipPath>

      <path
        id="scrollText"
        clipPath="url(#clipScroll)"
        d="M17,88s86-55,170,0"
      />
    </svg>
  );
}
