import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import "../svg/CourbeAbout.css";

import { I18nProvider } from "../../i18n";
import translate from "../../i18n/translate";

const useStyles = (isMobile) =>
  makeStyles(() => ({
    card: {
      width: "94vw",
      height: "94vh",
      backgroundColor: "#f0f2f5",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "3%",
    },
    closeIcon: {
      position: "fixed",
      top: "4vh",
      right: "3vw",
      cursor: "pointer",
      fontSize: "3.5vmin",
    },
    minimizedWidth: {
      width: "65%",
    },
    media: {
      height: "55vmin",
      width: "103vmin",
      marginBottom: "3%",
      backgroundPosition: "left",
    },
  }));

export default function HandicapsSco({
  isMobile,
  locale,
  creationOpened,
  handleCloseCreationOpened,
}) {
  useEffect(() => {});

  const classes = useStyles(isMobile)();

  return (
    <div>
      <Modal open={creationOpened} onClose={handleCloseCreationOpened}>
        <Card className={classes.card}>
          <CloseIcon
            className={classes.closeIcon}
            onClick={handleCloseCreationOpened}
          >
            X
          </CloseIcon>
          <Typography gutterBottom variant="h3" component="h2">
            <I18nProvider locale={locale}>
              {translate("creationTitleHandicapsSco")}
            </I18nProvider>
          </Typography>
          <CardMedia
            className={classes.media}
            image="/screenshotHandicapsSco.PNG"
            title="Capture d'écran de l'application HandicapsSco."
          />
          <Typography
            className={classes.minimizedWidth}
            gutterBottom
            variant="body1"
            component="p"
          >
            <I18nProvider locale={locale}>
              {translate("creationSummaryHandicapsSco")}
            </I18nProvider>
            <a
              href="https://www.handicap-scolarite.moscilloleo.fr/"
              target="_blank"
              rel="noreferrer"
            >
              handicap-scolarite.moscilloleo.fr/
            </a>
          </Typography>
          <Typography
            className={classes.minimizedWidth}
            gutterBottom
            variant="body1"
            component="p"
          >
            <I18nProvider locale={locale}>
              {translate("creationBodyHandicapsSco")}
            </I18nProvider>
          </Typography>
        </Card>
      </Modal>
    </div>
  );
}
