import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import "../svg/CourbeAbout.css";

import { I18nProvider } from "../../i18n";
import translate from "../../i18n/translate";

const useStyles = (isMobile) =>
  makeStyles(() => ({
    card: {
      width: "94vw",
      height: "94vh",
      backgroundColor: "#f0f2f5",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "3%",
    },
    closeIcon: {
      position: "fixed",
      top: "4vh",
      right: "3vw",
      cursor: "pointer",
      fontSize: "3.5vmin",
    },
    minimizedWidth: {
      width: "65%",
    },
    media: {
      height: "50vmin",
      width: "50vmin",
      marginBottom: "3%",
    },
  }));

export default function Chimere({
  isMobile,
  locale,
  creationOpened,
  handleCloseCreationOpened,
}) {
  useEffect(() => {});

  const classes = useStyles(isMobile)();

  return (
    <div>
      <Modal open={creationOpened} onClose={handleCloseCreationOpened}>
        <Card className={classes.card}>
          <CloseIcon
            className={classes.closeIcon}
            onClick={handleCloseCreationOpened}
          >
            X
          </CloseIcon>
          <Typography gutterBottom variant="h3" component="h2">
            <I18nProvider locale={locale}>
              {translate("creationTitleChimere")}
            </I18nProvider>
          </Typography>
          <CardMedia
            className={classes.media}
            image="/creations-gallery/Chimere.jpg"
            title="Chimère imaginé par Léo Moscillo"
          />
          <Typography
            className={classes.minimizedWidth}
            gutterBottom
            variant="body1"
            component="p"
          >
            <I18nProvider locale={locale}>
              {translate("creationSummaryChimere")}
            </I18nProvider>
          </Typography>
          <Typography
            className={classes.minimizedWidth}
            gutterBottom
            variant="body1"
            component="p"
          >
            <I18nProvider locale={locale}>
              {translate("creationBodyChimere")}
            </I18nProvider>
          </Typography>
        </Card>
      </Modal>
    </div>
  );
}
