/*


$$$$$$$$$$$$$$$$$$$$$$$$$$$**""""`` ````"""#*R$$$$$$$$$$$$$$$$$$$$$$$$$$$
$$$$$$$$$$$$$$$$$$$$$$*""      ..........      `"#$$$$$$$$$$$$$$$$$$$$$$$
$$$$$$$$$$$$$$$$$$$#"    .ue@$$$********$$$$Weu.   `"*$$$$$$$$$$$$$$$$$$$
$$$$$$$$$$$$$$$$#"   ue$$*#""              `""*$$No.   "R$$$$$$$$$$$$$$$$
$$$$$$$$$$$$$P"   u@$*"`                         "#$$o.  ^*$$$$$$$$$$$$$$
$$$$$$$$$$$P"  .o$R"               . .WN.           "#$Nu  `#$$$$$$$$$$$$
$$$$$$$$$$"  .@$#`       'ou  .oeW$$$$$$$$W            "$$u  "$$$$$$$$$$$
$$$$$$$$#   o$#`      ueL  $$$$$$$$$$$$$$$$ku.           "$$u  "$$$$$$$$$
$$$$$$$"  x$P`        `"$$u$$$$$$$$$$$$$$"#$$$L            "$o   *$$$$$$$
$$$$$$"  d$"        #$u.2$$$$$$$$$$$$$$$$  #$$$Nu            $$.  #$$$$$$
$$$$$"  @$"          $$$$$$$$$$$$$$$$$$$$k  $$#*$$u           #$L  #$$$$$
$$$$"  d$         #Nu@$$$$$$$$$$$$$$$$$$"  x$$L #$$$o.         #$c  #$$$$
$$$F  d$          .$$$$$$$$$$$$$$$$$$$$N  d$$$$  "$$$$$u        #$L  #$$$
$$$  :$F        ..`$$$$$$$$$$$$$$$$$$$$$$$$$$$`    R$$$$$eu.     $$   $$$
$$!  $$        . R$$$$$$$$$$$$$$$$$$$$$$$$$$$$$.   @$$$$$$$$Nu   '$N  `$$
$$  x$"        Re$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$uu@"``"$$$$$$$i   #$:  $$
$E  $$       c 8$$$$$$$$$$$$$$$$$$$$$G(   ``^*$$$$$$$WW$$$$$$$$N   $$  4$
$~ :$$N. tL i)$$$$$$$$$$$$$$$$$$$$$$$$$N       ^#R$$$$$$$$$$$$$$$  9$  '$
$  t$$$$u$$W$$$$$$$$$$$$$$!$$$$$$$$$$$$$&       . c?"*$$$R$$$$$$$  '$k  $
$  @$$$$$$$$$$$$$$$$$$$$"E F!$$$$$$$$$$."        +."@\* x .""*$$"   $B  $
$  $$$$$$$$$$$$$$$$"$)#F     $$$$$$$$$$$           `  -d>x"*=."`    $$  $
$  $$$$$$$$$$?$$R'$ `#d$""    #$$$$$$$$$ > .                "       $$  $
$  $$$$$$$($$@$"` P *@$.@#"!    "*$$$$$$$L!.                        $$  $
$  9$$$$$$$L#$L  ! " <$$`          "*$$$$$NL:"z  f                  $E  $
$> ?$$$$ $$$b$^      .$c .ueu.        `"$$$$b"x"#  "               x$!  $
$k  $$$$N$ "$$L:$oud$$$` d$ .u.         "$$$$$o." #f.              $$   $
$$  R$""$$o.$"$$$$""" ue$$$P"`"c          "$$$$$$Wo'              :$F  t$
$$: '$&  $*$$u$$$$u.ud$R" `    ^            "#*****               @$   $$
$$N  #$: E 3$$$$$$$$$"                                           d$"  x$$
$$$k  $$   F *$$$$*"                                            :$P   $$$
$$$$  '$b                                                      .$P   $$$$
$$$$b  `$b                                                    .$$   @$$$$
$$$$$N  "$N                                                  .$P   @$$$$$
$$$$$$N  '*$c                                               u$#  .$$$$$$$
$$$$$$$$.  "$N.                                           .@$"  x$$$$$$$$
$$$$$$$$$o   #$N.                                       .@$#  .@$$$$$$$$$
$$$$$$$$$$$u  `#$Nu                                   u@$#   u$$$$$$$$$$$
$$$$$$$$$$$$$u   "R$o.                             ue$R"   u$$$$$$$$$$$$$
$$$$$$$$$$$$$$$o.  ^#$$bu.                     .uW$P"`  .u$$$$$$$$$$$$$$$
$$$$$$$$$$$$$$$$$$u   `"#R$$Wou..... ....uueW$$*#"   .u@$$$$$$$$$$$$$$$$$
$$$$$$$$$$$$$$$$$$$$Nu.    `""#***$$$$$***"""`    .o$$$$$$$$$$$$$$$$$$$$$
$$$$$$$$$$$$$$$$$$$$$$$$$eu..               ...ed$$$$$$$$$$$$$$$$$$$$$$$$
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$NWWeeeeedW@$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

 __          __  _                            _               _                         _        _____           _    
 \ \        / / | |                          | |             | |                       (_)      |  __ \         | |   
  \ \  /\  / /__| | ___ ___  _ __ ___   ___  | |_ ___        | |_   _ _ __ __ _ ___ ___ _  ___  | |__) |_ _ _ __| | __
   \ \/  \/ / _ \ |/ __/ _ \| '_ ` _ \ / _ \ | __/ _ \   _   | | | | | '__/ _` / __/ __| |/ __| |  ___/ _` | '__| |/ /
    \  /\  /  __/ | (_| (_) | | | | | |  __/ | || (_) | | |__| | |_| | | | (_| \__ \__ \ | (__  | |  | (_| | |  |   < 
     \/  \/ \___|_|\___\___/|_| |_| |_|\___|  \__\___/   \____/ \__,_|_|  \__,_|___/___/_|\___| |_|   \__,_|_|  |_|\_\
                                                                                                                      
                                                                                                                      

*/

import * as PIXI from 'pixi.js'
import $ from "jquery";

var wST, hST;
var stage;

var VitesseMin = 2;
var VitesseMax = 3;
var RotationMin = 0.01;
var RotationMax = 0.03;
var SizeMin = 30;
var SizeMax = 150;
// var DelayMin = 30;
// var DelayMax = 90;
var YMin = 0;
var YMax = window.innerHeight;

var SquareValArray = [];
var SquareArray = [];

$(document).ready(function() {

	wST = window.innerWidth;
	hST = window.innerHeight;
	
    var renderer = PIXI.autoDetectRenderer(800, 900, { antialias: true  });
    renderer.resize(wST, hST);
    $('#home').append(renderer.view);
    
    stage = new PIXI.Container();
    stage.interactive = false;
    var background = new PIXI.Sprite(gradient('#75d9c6', '#50a3a2'));
    background.position.set(0, 0);
    background.width = wST;
    background.height = hST;
    stage.addChild(background);

    SummonSquare();

    animate();
    function animate() {
        renderer.render(stage);
        for(var i = 0; i < SquareArray.length; i++){
            SquareArray[i].x += SquareValArray[i].Vitesse;
            if(SquareArray[i].x >= window.innerWidth + SquareValArray[i].Size){
                stage.removeChild(SquareArray[i]);
                SquareArray.splice(i, 1);
                SquareValArray.splice(i, 1);
                CreateSquare(stage);
            }
            SquareArray[i].rotation += SquareValArray[i].Rotation;
        }
        requestAnimationFrame( animate );
    }
    
    window.addEventListener('resize', resize);
    function resize() {
        wST = window.innerWidth;
        hST = window.innerHeight;
        renderer.resize(wST, hST);
        background.width = wST;
        background.height = hST;
    };
    

});

    export function SummonSquare(){
        CreateSquare(stage);
        CreateSquare(stage);
        CreateSquare(stage);
    }
   
    function CreateSquare(stage){
        var CarreVal = {'Vitesse' : RandomVitesse(), 'Rotation' : RandomRotation(), 'Size' : RandomSize(), 'Y' : RandomY() }
        SquareValArray.push(CarreVal);
        var Square = new PIXI.Graphics();
        Square.beginFill(0x50a3a2, 0.4);
        Square.drawRect( 0, 0, CarreVal.Size, CarreVal.Size);
        Square.y = CarreVal.Y + CarreVal.Size;
        Square.x = -CarreVal.Size + 10;
        Square.pivot.set(CarreVal.Size / 2 , CarreVal.Size / 2);
        Square.endFill();
        stage.addChild(Square);
        SquareArray.push(Square);
    }
    
    function RandomVitesse(){
        return VitesseMin + (Math.random() * (VitesseMax - VitesseMin));    
    }
    function RandomRotation(){
        return RotationMin + (Math.random() * (RotationMax-RotationMin));    
    }
    function RandomSize(){
        return SizeMin + (Math.random() * (SizeMax-SizeMin));    
    }
    // function RandomDelay(){
    //     return DelayMin + (Math.random() * (DelayMax-DelayMin));    
    // }
    function RandomY(){
        return YMin + (Math.random() * (YMax-YMin-SizeMax));    
    }
    function gradient(from, to) {
        const c = document.createElement("canvas");
        c.width = wST;
        c.height = hST;
        const ctx = c.getContext("2d");
        const grd = ctx.createLinearGradient(100, hST/2,wST,hST);
        grd.addColorStop(0, from);
        grd.addColorStop(1, to);
        ctx.fillStyle = grd;
        ctx.fillRect(0,0,wST*8,hST*8);
        return new PIXI.Texture.from(c);
      }