import "./MainTitle.css";
import $ from "jquery";

export default function MainTitle() {
  $("body").on("mousemove", showCoords);

  function showCoords(event) {
    const OmbreAcceuil = document.querySelectorAll(".OmbreAcceuil");
    var MouseX = event.clientX;
    var OmbreX =
      (-1 * (MouseX - window.innerWidth / 2)) / (window.innerWidth / 11);
    var OmbreX1 =
      (-1 * (MouseX - window.innerWidth / 2)) / (window.innerWidth / 22);
    var OmbreX2 =
      (-1 * (MouseX - window.innerWidth / 2)) / (window.innerWidth / 9);

    for (let i = 0; i < OmbreAcceuil.length; i++) {
      OmbreAcceuil[i].style.transform = "translateX(" + OmbreX + "px)";
    }
    $(".OmbreAcceuil1").css("transform", "translateX(" + OmbreX1 + "px)");
    $(".OmbreAcceuil2").css("transform", "translateX(" + OmbreX2 + "px)");
  }

  return (
    <svg
      id="TitreAcceuil"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1130.33 275.83"
    >
      <g id="T1">
        <path
          className="cls2-1"
          d="M147.11,44.67h22.36v142H147.11Zm11,65.52h35.65a15,15,0,0,0,7.91-2,13.17,13.17,0,0,0,5.22-6,21.35,21.35,0,0,0,1.81-9.09V83.53a22.9,22.9,0,0,0-1.76-9.32,13,13,0,0,0-5.22-6.06,15.09,15.09,0,0,0-8-2.1H158.14V44.67H193.5q11.72,0,20.26,4.54A31.6,31.6,0,0,1,227,62.34a41.28,41.28,0,0,1,4.69,20.12V94.18A40.51,40.51,0,0,1,227,114.1a31.53,31.53,0,0,1-13.28,13,43,43,0,0,1-20.21,4.49H158.14Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M270.06,182.85A36.69,36.69,0,0,1,255,167.76q-5.33-9.81-5.32-23V86.56q0-13.28,5.32-23a36.71,36.71,0,0,1,15.09-15q9.76-5.27,23-5.27T316,48.56a36.71,36.71,0,0,1,15.09,15q5.31,9.76,5.32,23v58.2q0,13.19-5.32,23A36.69,36.69,0,0,1,316,182.85q-9.76,5.28-22.95,5.27T270.06,182.85ZM304,163.42a17.3,17.3,0,0,0,7.12-7.52,25.39,25.39,0,0,0,2.45-11.53V86.85a25.17,25.17,0,0,0-2.45-11.42A17.24,17.24,0,0,0,304,67.91a24,24,0,0,0-21.88,0,17.6,17.6,0,0,0-7.18,7.52,24.79,24.79,0,0,0-2.49,11.42v57.52a25.11,25.11,0,0,0,2.49,11.53,17.72,17.72,0,0,0,7.18,7.52,24,24,0,0,0,21.88,0Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M358.73,44.57H381V186.66H358.73Zm9.77,61.33h37.4a15.45,15.45,0,0,0,7.76-1.91,12.39,12.39,0,0,0,5.13-5.47A20,20,0,0,0,420.64,90V82.26a20,20,0,0,0-1.75-8.59A12.9,12.9,0,0,0,413.76,68a15.15,15.15,0,0,0-7.86-2H368.5V44.57h36.91q11.71,0,20.31,4.44A31.5,31.5,0,0,1,439,61.8q4.69,8.36,4.69,19.68v8.3q0,11.53-4.69,20a31.71,31.71,0,0,1-13.33,13,42.88,42.88,0,0,1-20.26,4.54H368.5Zm28.12,17,22.07-4.49,30,68.26h-25.6Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M454.43,44.67h86.14V66.05H454.43Zm31.94,11.71h22.17V186.66H486.37Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M555.61,44.67H578v142H555.61Zm10,0h66.7V66.05h-66.7Zm0,62.2H624.5V128H565.66Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M667.28,182.85a36.66,36.66,0,0,1-15-15.09Q647,158,647,144.76V86.56q0-13.28,5.33-23a36.63,36.63,0,0,1,15.08-15q9.76-5.27,23-5.27t23,5.27a36.71,36.71,0,0,1,15.09,15q5.32,9.76,5.32,23v58.2q0,13.19-5.32,23a36.69,36.69,0,0,1-15.09,15.09q-9.76,5.28-23,5.27T667.28,182.85Zm33.89-19.43a17.27,17.27,0,0,0,7.13-7.52,25.51,25.51,0,0,0,2.44-11.53V86.85a25.16,25.16,0,0,0-2.44-11.42,17.27,17.27,0,0,0-7.13-7.52,24,24,0,0,0-21.87,0,17.55,17.55,0,0,0-7.18,7.52,24.79,24.79,0,0,0-2.49,11.42v57.52a25.11,25.11,0,0,0,2.49,11.53,17.6,17.6,0,0,0,7.18,7.52,24,24,0,0,0,21.87,0Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M756,44.67h22.36v142H756Zm10.45,120.6h66.21v21.39H766.45Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M871.72,186.66H849.16v-142h22.56Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M915.47,182.85a36.69,36.69,0,0,1-15.09-15.09q-5.32-9.81-5.32-23V86.56q0-13.28,5.32-23a36.71,36.71,0,0,1,15.09-15q9.76-5.27,23-5.27t22.95,5.27a36.71,36.71,0,0,1,15.09,15q5.31,9.76,5.32,23v58.2q0,13.19-5.32,23a36.69,36.69,0,0,1-15.09,15.09q-9.76,5.28-22.95,5.27T915.47,182.85Zm33.89-19.43a17.38,17.38,0,0,0,7.13-7.52,25.64,25.64,0,0,0,2.44-11.53V86.85a25.32,25.32,0,0,0-2.44-11.42,17.27,17.27,0,0,0-7.13-7.52,24,24,0,0,0-21.88,0,17.6,17.6,0,0,0-7.18,7.52,24.79,24.79,0,0,0-2.49,11.42v57.52a25.12,25.12,0,0,0,2.47,11.53,17.66,17.66,0,0,0,7.18,7.52,24,24,0,0,0,21.88,0Z"
          transform="translate(0)"
        />
      </g>
      <g id="T1Ombre" className="cls2-2 OmbreAcceuil1">
        <path
          className="cls2-1"
          d="M147.16,44.23h22.36v142H147.16Zm11,65.52h35.65a15.06,15.06,0,0,0,7.91-2,13.17,13.17,0,0,0,5.22-6,21.38,21.38,0,0,0,1.81-9.09V83.09A22.9,22.9,0,0,0,207,73.77a13.06,13.06,0,0,0-5.22-6.06,15.09,15.09,0,0,0-8-2.1H158.19V44.23h35.36q11.7,0,20.26,4.54A31.6,31.6,0,0,1,227,61.9,41.39,41.39,0,0,1,231.73,82V93.74A40.62,40.62,0,0,1,227,113.66a31.53,31.53,0,0,1-13.28,13,43.15,43.15,0,0,1-20.21,4.49H158.19Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M270.11,182.41A36.63,36.63,0,0,1,255,167.32q-5.34-9.81-5.32-23V86.12q0-13.28,5.32-23a36.71,36.71,0,0,1,15.09-15q9.75-5.26,23-5.27t22.95,5.27a36.71,36.71,0,0,1,15.09,15q5.31,9.76,5.32,23v58.2q0,13.19-5.32,23a36.75,36.75,0,0,1-15.09,15.09q-9.76,5.28-22.95,5.27T270.11,182.41ZM304,163a17.35,17.35,0,0,0,7.12-7.52,25.65,25.65,0,0,0,2.45-11.53V86.41A25.17,25.17,0,0,0,311.12,75,17.24,17.24,0,0,0,304,67.47a24,24,0,0,0-21.88,0A17.6,17.6,0,0,0,274.94,75a24.79,24.79,0,0,0-2.49,11.42v57.52a25,25,0,0,0,2.49,11.53,17.6,17.6,0,0,0,7.18,7.52A24,24,0,0,0,304,163Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M358.78,44.13h22.27V186.22H358.78Zm9.77,61.33H406a15.58,15.58,0,0,0,7.76-1.91,12.45,12.45,0,0,0,5.13-5.47,20,20,0,0,0,1.85-8.54V81.82a20,20,0,0,0-1.75-8.59,12.85,12.85,0,0,0-5.13-5.71,15.05,15.05,0,0,0-7.86-2h-37.4V44.13h36.91q11.71,0,20.31,4.44a31.5,31.5,0,0,1,13.28,12.79A39.63,39.63,0,0,1,443.74,81v8.3q0,11.53-4.69,20a31.76,31.76,0,0,1-13.33,13,42.88,42.88,0,0,1-20.26,4.54H368.55Zm28.12,17L418.74,118l30,68.26h-25.6Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M454.48,44.23h86.14V65.61H454.48Zm31.94,11.71h22.17V186.22H486.42Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M555.66,44.23H578v142H555.66Zm10,0h66.7V65.61h-66.7Zm0,62.2h58.89v21.13H565.71Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M667.33,182.41a36.6,36.6,0,0,1-15-15.09q-5.33-9.81-5.33-23V86.12q0-13.28,5.33-23a36.63,36.63,0,0,1,15.08-15q9.75-5.26,23-5.27t23,5.27a36.71,36.71,0,0,1,15.09,15q5.32,9.76,5.32,23v58.2q0,13.19-5.32,23a36.75,36.75,0,0,1-15.09,15.09q-9.76,5.28-23,5.27T667.33,182.41ZM701.22,163a17.38,17.38,0,0,0,7.13-7.52,25.51,25.51,0,0,0,2.44-11.53V86.41A25.16,25.16,0,0,0,708.35,75a17.27,17.27,0,0,0-7.13-7.52,24,24,0,0,0-21.87,0A17.49,17.49,0,0,0,672.17,75a24.79,24.79,0,0,0-2.49,11.42v57.52a25,25,0,0,0,2.49,11.53,17.6,17.6,0,0,0,7.18,7.52,24,24,0,0,0,21.87,0Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M756.05,44.23h22.36v142H756.05Zm10.45,120.6h66.21v21.39H766.5Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M871.77,186.22H849.21v-142h22.56Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M915.52,182.41a36.63,36.63,0,0,1-15.09-15.09q-5.32-9.81-5.32-23V86.12q0-13.28,5.32-23a36.71,36.71,0,0,1,15.09-15q9.75-5.26,23-5.27t23,5.27a36.71,36.71,0,0,1,15.09,15q5.31,9.76,5.32,23v58.2q0,13.19-5.32,23a36.75,36.75,0,0,1-15.09,15.09q-9.78,5.28-23,5.27T915.52,182.41ZM949.41,163a17.43,17.43,0,0,0,7.13-7.52A25.64,25.64,0,0,0,959,143.93V86.41A25.29,25.29,0,0,0,956.54,75a17.32,17.32,0,0,0-7.13-7.52,24,24,0,0,0-21.88,0A17.6,17.6,0,0,0,920.35,75a24.79,24.79,0,0,0-2.49,11.42v57.52a25,25,0,0,0,2.47,11.53,17.6,17.6,0,0,0,7.18,7.52,24,24,0,0,0,21.88,0Z"
          transform="translate(0)"
        />
      </g>

      <path
        id="AcceuilSup"
        className="cls2-1"
        d="M0,109,67.82,73V92.76L17.64,117.9l50.18,25.45v20.11L0,127.05Z"
        transform="translate(0)"
      />

      <path
        id="AcceuilInf"
        className="cls2-1"
        d="M1130.33,125.39l-67.81,36V141.64l50.18-25.15L1062.52,91V70.93l67.81,36.4Z"
        transform="translate(0)"
      />

      <g id="T2">
        <path
          className="cls2-1"
          d="M241.85,218.77h9.43l3.17,26.75,4.83-26.75h7.66l4.77,26.75,3.16-26.75h9.41l-8.86,40.54h-7.58l-4.77-26.95-4.57,26.95h-7.77Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M293.94,258.43a10.07,10.07,0,0,1-4.39-4.25,13.84,13.84,0,0,1-1.53-6.74V231.27a15.74,15.74,0,0,1,1.49-7.15,10.18,10.18,0,0,1,4.27-4.51,13.74,13.74,0,0,1,6.74-1.55,14.43,14.43,0,0,1,6.86,1.51,10.09,10.09,0,0,1,4.33,4.39,15,15,0,0,1,1.51,7v11.49H295.83v-7.07h8v-4.85a4.14,4.14,0,0,0-.88-2.81,3,3,0,0,0-2.44-1,2.65,2.65,0,0,0-2.28,1.07,5,5,0,0,0-.8,3v17a3.5,3.5,0,0,0,.88,2.58,4,4,0,0,0,4.86.15,2.66,2.66,0,0,0,.82-2.1V247l9.33.08v1.13c0,3.72-1.09,6.61-3.28,8.65s-5.26,3.07-9.22,3.07A14.89,14.89,0,0,1,293.94,258.43Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M320.21,202.52h9.69v56.79h-9.69Zm12.21,55.56a11,11,0,0,1-3.07-5.13l.55-5.71a5.35,5.35,0,0,0,.37,2.13,2.79,2.79,0,0,0,1.07,1.33,3,3,0,0,0,1.68.45,2.59,2.59,0,0,0,2.23-1.06,5,5,0,0,0,.78-3V230.68a4.58,4.58,0,0,0-.78-2.85,2.65,2.65,0,0,0-2.23-1,3.27,3.27,0,0,0-1.7.41,2.73,2.73,0,0,0-1,1.25,4.87,4.87,0,0,0-.37,2l-.82-5a14.06,14.06,0,0,1,3.51-5.46,6.88,6.88,0,0,1,4.77-2,7.45,7.45,0,0,1,4.41,1.31,8,8,0,0,1,2.8,3.81,17.31,17.31,0,0,1,1,6v19.26a17.51,17.51,0,0,1-1,6.17,8.28,8.28,0,0,1-2.86,3.91,7.5,7.5,0,0,1-4.49,1.33A7,7,0,0,1,332.42,258.08Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M373.28,234.31v9.3H352.4v-9.3Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M383.78,258.69a8,8,0,0,1-2.71-3.77,16.79,16.79,0,0,1-.94-6V229.08a16.27,16.27,0,0,1,1-5.94,8.19,8.19,0,0,1,2.73-3.75,7.11,7.11,0,0,1,4.28-1.29A7.36,7.36,0,0,1,393,220a13.07,13.07,0,0,1,3.58,5.41l-.82,5.07a5.09,5.09,0,0,0-.35-2,2.73,2.73,0,0,0-1-1.25,3.12,3.12,0,0,0-1.66-.43,2.85,2.85,0,0,0-2.3,1,4.17,4.17,0,0,0-.83,2.77v16.95a3.87,3.87,0,0,0,.83,2.66,2.86,2.86,0,0,0,2.3.94,3.22,3.22,0,0,0,1.66-.41,2.68,2.68,0,0,0,1-1.23,5.09,5.09,0,0,0,.35-2l.48,5.48a10.79,10.79,0,0,1-3.16,5.17,7.42,7.42,0,0,1-5,1.86A7.24,7.24,0,0,1,383.78,258.69Zm12-56.17h9.68v56.79h-9.68Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M418.28,258.43a10.09,10.09,0,0,1-4.4-4.25,14.06,14.06,0,0,1-1.52-6.74V231.27a15.89,15.89,0,0,1,1.48-7.15,10.2,10.2,0,0,1,4.28-4.51,13.74,13.74,0,0,1,6.74-1.55,14.39,14.39,0,0,1,6.85,1.51,10.11,10.11,0,0,1,4.34,4.39,15,15,0,0,1,1.5,7v11.49H420.13v-7.07h8v-4.85a4.14,4.14,0,0,0-.88-2.81,3,3,0,0,0-2.44-1,2.68,2.68,0,0,0-2.29,1.07,5,5,0,0,0-.8,3v17a3.59,3.59,0,0,0,.88,2.58,4,4,0,0,0,4.87.15,2.67,2.67,0,0,0,.82-2.1V247l9.33.08v1.13c0,3.72-1.09,6.61-3.28,8.65s-5.26,3.07-9.22,3.07A15,15,0,0,1,418.28,258.43Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M448.57,258.34a11.23,11.23,0,0,1-4.49-4.34,12.67,12.67,0,0,1-1.6-6.46v-.67l9-.08v.63a3.79,3.79,0,0,0,1,2.75,3.54,3.54,0,0,0,2.68,1.08,3.6,3.6,0,0,0,2.64-1,3.5,3.5,0,0,0,1-2.56,3.4,3.4,0,0,0-.72-2.19,5.67,5.67,0,0,0-1.8-1.5c-.73-.38-1.74-.83-3-1.35l-.73-.29-.72-.29a.64.64,0,0,0-.19-.08l-.2-.08a20.94,20.94,0,0,1-4.24-2.42,10.36,10.36,0,0,1-3-3.67,12.36,12.36,0,0,1-1.18-5.71,13.1,13.1,0,0,1,1.49-6.31,10.43,10.43,0,0,1,4.18-4.25,12.42,12.42,0,0,1,6.17-1.51,12.85,12.85,0,0,1,6.25,1.47,10.26,10.26,0,0,1,4.2,4.16,12.48,12.48,0,0,1,1.5,6.21v.7l-9,.12v-.82a3.3,3.3,0,0,0-.8-2.29,2.76,2.76,0,0,0-2.09-.88,2.54,2.54,0,0,0-2,.94,3.66,3.66,0,0,0-.78,2.42,3.74,3.74,0,0,0,.78,2.45,5.9,5.9,0,0,0,2,1.54,32.12,32.12,0,0,0,3.24,1.29l.14.06.13.05.16.06.16.06a23.28,23.28,0,0,1,4.7,2.17,9.58,9.58,0,0,1,3.21,3.57,13.07,13.07,0,0,1,1.31,6.33,12.61,12.61,0,0,1-1.59,6.41,10.83,10.83,0,0,1-4.47,4.3,14.19,14.19,0,0,1-6.64,1.52A13.8,13.8,0,0,1,448.57,258.34Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M472.55,202.52h9.73v9.61h-9.69Zm0,16.25h9.73v40.54h-9.69Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M492,258.69a8,8,0,0,1-2.74-3.77,17,17,0,0,1-.94-6V229.08a16.69,16.69,0,0,1,.94-5.94,8,8,0,0,1,2.74-3.75,7.44,7.44,0,0,1,9.2.65,13.66,13.66,0,0,1,3.57,5.41l-.82,5.07a5.09,5.09,0,0,0-.35-2,2.73,2.73,0,0,0-1-1.25,3,3,0,0,0-1.66-.43,2.85,2.85,0,0,0-2.3,1,4.13,4.13,0,0,0-.82,2.77v16.95a3.93,3.93,0,0,0,.82,2.66,2.9,2.9,0,0,0,2.3.94,3.13,3.13,0,0,0,1.66-.41,2.68,2.68,0,0,0,1-1.23,5.09,5.09,0,0,0,.35-2l.55,5.43a11.22,11.22,0,0,1-3.17,5.17,7.31,7.31,0,0,1-5,1.86A7.21,7.21,0,0,1,492,258.69Zm-.24,14.06c-2.16-2-3.24-4.9-3.24-8.59v-2l9.65-.07v2a2.58,2.58,0,0,0,.78,2,3.11,3.11,0,0,0,2.19.72,2.9,2.9,0,0,0,2.07-.7,2.61,2.61,0,0,0,.74-2V218.77h9.69v45.35a12.37,12.37,0,0,1-1.51,6.33,9.68,9.68,0,0,1-4.37,4,16.06,16.06,0,0,1-6.9,1.37Q495,275.81,491.77,272.75Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M521.38,218.77h9.77v40.54h-9.77Zm15.9,11.91a4.63,4.63,0,0,0-.78-2.91,2.82,2.82,0,0,0-2.35-1,2.73,2.73,0,0,0-2.28,1,4.62,4.62,0,0,0-.76,2.89l-.82-4.8a12.8,12.8,0,0,1,3.57-5.77,7.71,7.71,0,0,1,5.18-2,7,7,0,0,1,5.84,2.7c1.39,1.79,2.08,4.31,2.09,7.58v31h-9.69Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M559.8,258.43a10.07,10.07,0,0,1-4.39-4.25,13.84,13.84,0,0,1-1.53-6.74V231.27a15.74,15.74,0,0,1,1.49-7.15,10.18,10.18,0,0,1,4.27-4.51,13.74,13.74,0,0,1,6.74-1.55,14.43,14.43,0,0,1,6.86,1.51,10.09,10.09,0,0,1,4.33,4.39,15,15,0,0,1,1.51,7v11.49H561.69v-7.07h8v-4.85a4.14,4.14,0,0,0-.88-2.81,3,3,0,0,0-2.44-1,2.65,2.65,0,0,0-2.28,1.07,5,5,0,0,0-.8,3v17a3.5,3.5,0,0,0,.88,2.58,4,4,0,0,0,4.86.15,2.66,2.66,0,0,0,.82-2.1V247l9.34.08v1.13c0,3.72-1.1,6.61-3.29,8.65s-5.26,3.07-9.21,3.07A15.06,15.06,0,0,1,559.8,258.43Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M586.07,218.77h9.69v40.54h-9.69Zm19,9.53h-2a6.91,6.91,0,0,0-5.35,2.07,8,8,0,0,0-1.91,5.62L595,225.84a15.25,15.25,0,0,1,4.51-5.71,9.83,9.83,0,0,1,6-2.07V228.3Z"
          transform="translate(0)"
        />
      </g>
      <g id="T2Ombre" className="cls2-2 OmbreAcceuil2">
        <path
          className="cls2-1"
          d="M241.73,218.66h9.43l3.17,26.75,4.83-26.75h7.66l4.77,26.75,3.16-26.75h9.41L275.3,259.2h-7.58L263,232.25l-4.57,26.95h-7.77Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M293.82,258.32a10,10,0,0,1-4.39-4.25,13.84,13.84,0,0,1-1.53-6.74V231.16a15.79,15.79,0,0,1,1.49-7.15,10.18,10.18,0,0,1,4.27-4.51A13.8,13.8,0,0,1,300.4,218a14.45,14.45,0,0,1,6.86,1.51,10.14,10.14,0,0,1,4.33,4.39,15,15,0,0,1,1.51,7v11.49H295.71v-7.07h8v-4.85a4.19,4.19,0,0,0-.88-2.81,3,3,0,0,0-2.44-1,2.64,2.64,0,0,0-2.28,1.07,5,5,0,0,0-.8,3v17a3.47,3.47,0,0,0,.88,2.58,4,4,0,0,0,4.86.15,2.66,2.66,0,0,0,.82-2.1v-1.42l9.33.08v1.13c0,3.72-1.09,6.61-3.28,8.65s-5.26,3.07-9.22,3.07A14.89,14.89,0,0,1,293.82,258.32Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M320.09,202.41h9.69V259.2h-9.69ZM332.3,258a10.88,10.88,0,0,1-3.07-5.13l.55-5.71a5.18,5.18,0,0,0,.37,2.13,2.75,2.75,0,0,0,1.07,1.33,3,3,0,0,0,1.68.45,2.59,2.59,0,0,0,2.23-1.06,5,5,0,0,0,.78-3V230.57a4.65,4.65,0,0,0-.78-2.85,2.65,2.65,0,0,0-2.23-1,3.24,3.24,0,0,0-1.7.41,2.67,2.67,0,0,0-1,1.25,4.93,4.93,0,0,0-.37,2l-.82-5a14.13,14.13,0,0,1,3.51-5.46,6.88,6.88,0,0,1,4.77-2,7.47,7.47,0,0,1,4.41,1.31,8.07,8.07,0,0,1,2.8,3.81,17.31,17.31,0,0,1,1,6.05v19.26a17.51,17.51,0,0,1-1,6.17,8.23,8.23,0,0,1-2.86,3.91,7.5,7.5,0,0,1-4.49,1.33A6.92,6.92,0,0,1,332.3,258Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M373.16,234.2v9.3H352.28v-9.3Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M383.66,258.58a8,8,0,0,1-2.71-3.77,16.54,16.54,0,0,1-.94-6V229a16.27,16.27,0,0,1,1-5.94,8.05,8.05,0,0,1,2.73-3.75A7.11,7.11,0,0,1,388,218a7.38,7.38,0,0,1,4.9,1.94,13.34,13.34,0,0,1,3.58,5.41l-.82,5.07a5.48,5.48,0,0,0-.35-2,2.85,2.85,0,0,0-1-1.25,3.24,3.24,0,0,0-1.66-.43,2.85,2.85,0,0,0-2.3,1,4.13,4.13,0,0,0-.83,2.77v16.95a3.82,3.82,0,0,0,.83,2.66,2.86,2.86,0,0,0,2.3.94,3.13,3.13,0,0,0,1.66-.41,2.74,2.74,0,0,0,1-1.23,5.4,5.4,0,0,0,.35-2l.48,5.48a10.82,10.82,0,0,1-3.16,5.17,7.42,7.42,0,0,1-5,1.86A7.09,7.09,0,0,1,383.66,258.58Zm12-56.17h9.68V259.2h-9.68Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M418.16,258.32a10,10,0,0,1-4.4-4.25,13.83,13.83,0,0,1-1.52-6.74V231.16a15.78,15.78,0,0,1,1.48-7.15A10.2,10.2,0,0,1,418,219.5a13.8,13.8,0,0,1,6.74-1.55,14.41,14.41,0,0,1,6.85,1.51,10.22,10.22,0,0,1,4.34,4.39,15.13,15.13,0,0,1,1.5,7v11.49H420v-7.07h8v-4.85a4.19,4.19,0,0,0-.88-2.81,3,3,0,0,0-2.44-1,2.68,2.68,0,0,0-2.29,1.07,5,5,0,0,0-.8,3v17a3.54,3.54,0,0,0,.88,2.58,4,4,0,0,0,4.87.15,2.73,2.73,0,0,0,.82-2.1v-1.42l9.33.08v1.13c0,3.72-1.09,6.61-3.28,8.65s-5.26,3.07-9.22,3.07A14.83,14.83,0,0,1,418.16,258.32Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M448.45,258.23a11.16,11.16,0,0,1-4.49-4.34,12.67,12.67,0,0,1-1.6-6.46v-.67l9-.08v.63a3.76,3.76,0,0,0,1,2.75,3.54,3.54,0,0,0,2.68,1.08,3.6,3.6,0,0,0,2.64-1,3.74,3.74,0,0,0,.28-4.75,5.78,5.78,0,0,0-1.8-1.5c-.73-.38-1.74-.83-3-1.35l-.73-.29-.72-.29-.19-.08-.2-.08a20.67,20.67,0,0,1-4.24-2.42,10.28,10.28,0,0,1-3-3.67A12.24,12.24,0,0,1,442.9,230a13.1,13.1,0,0,1,1.49-6.31,10.43,10.43,0,0,1,4.18-4.25,12.39,12.39,0,0,1,6.17-1.51A13,13,0,0,1,461,219.4a10.45,10.45,0,0,1,4.2,4.16,12.7,12.7,0,0,1,1.5,6.21v.7l-9,.12v-.82a3.36,3.36,0,0,0-.8-2.29,2.76,2.76,0,0,0-2.09-.88,2.52,2.52,0,0,0-2,.94A3.66,3.66,0,0,0,452,230a3.74,3.74,0,0,0,.78,2.45,5.6,5.6,0,0,0,2,1.54,30.35,30.35,0,0,0,3.24,1.29l.14.06.13,0,.16.06.16.06a24.11,24.11,0,0,1,4.7,2.17,9.67,9.67,0,0,1,3.21,3.57,13.33,13.33,0,0,1,1.31,6.33,12.81,12.81,0,0,1-1.59,6.41,10.89,10.89,0,0,1-4.47,4.3,14.07,14.07,0,0,1-6.64,1.52A13.56,13.56,0,0,1,448.45,258.23Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M472.43,202.41h9.73V212h-9.69Zm0,16.25h9.73V259.2h-9.69Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M491.89,258.58a7.89,7.89,0,0,1-2.74-3.77,16.79,16.79,0,0,1-.94-6V229a16.69,16.69,0,0,1,.94-5.94,8,8,0,0,1,2.74-3.75,7.44,7.44,0,0,1,9.2.65,13.77,13.77,0,0,1,3.57,5.41l-.82,5.07a5.48,5.48,0,0,0-.35-2,2.73,2.73,0,0,0-1-1.25,3.24,3.24,0,0,0-1.66-.43,2.85,2.85,0,0,0-2.3,1,4.13,4.13,0,0,0-.82,2.77v16.95a3.81,3.81,0,0,0,.82,2.66,2.86,2.86,0,0,0,2.3.94,3.13,3.13,0,0,0,1.66-.41,2.68,2.68,0,0,0,1-1.23,5.4,5.4,0,0,0,.35-2l.55,5.43a11.39,11.39,0,0,1-3.17,5.17,7.28,7.28,0,0,1-5,1.86A7.17,7.17,0,0,1,491.89,258.58Zm-.24,14.06c-2.16-2-3.24-4.9-3.24-8.59v-2l9.65-.07v2a2.58,2.58,0,0,0,.78,2,3.11,3.11,0,0,0,2.19.72,2.87,2.87,0,0,0,2.07-.7,2.69,2.69,0,0,0,.74-2V218.66h9.69V264a12.57,12.57,0,0,1-1.51,6.33,9.72,9.72,0,0,1-4.37,4,16.06,16.06,0,0,1-6.9,1.37Q494.9,275.7,491.65,272.64Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M521.26,218.66H531V259.2h-9.77Zm15.9,11.91a4.69,4.69,0,0,0-.78-2.91,2.83,2.83,0,0,0-2.35-1,2.73,2.73,0,0,0-2.28,1,4.56,4.56,0,0,0-.76,2.89l-.82-4.8a12.71,12.71,0,0,1,3.57-5.77,7.74,7.74,0,0,1,5.18-2,7,7,0,0,1,5.84,2.7q2.07,2.69,2.09,7.58v31h-9.69Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M559.68,258.32a10,10,0,0,1-4.39-4.25,13.84,13.84,0,0,1-1.53-6.74V231.16a15.79,15.79,0,0,1,1.49-7.15,10.18,10.18,0,0,1,4.27-4.51,13.8,13.8,0,0,1,6.74-1.55,14.45,14.45,0,0,1,6.86,1.51,10.14,10.14,0,0,1,4.33,4.39,15,15,0,0,1,1.51,7v11.49H561.57v-7.07h8v-4.85a4.19,4.19,0,0,0-.88-2.81,3,3,0,0,0-2.44-1,2.64,2.64,0,0,0-2.28,1.07,5,5,0,0,0-.8,3v17a3.47,3.47,0,0,0,.88,2.58,4,4,0,0,0,4.86.15,2.66,2.66,0,0,0,.82-2.1v-1.42l9.34.08v1.13c0,3.72-1.1,6.61-3.29,8.65s-5.26,3.07-9.21,3.07A14.9,14.9,0,0,1,559.68,258.32Z"
          transform="translate(0)"
        />
        <path
          className="cls2-1"
          d="M586,218.66h9.69V259.2H586Zm19,9.53h-2a7,7,0,0,0-5.35,2.07,8,8,0,0,0-1.91,5.62l-.82-10.15a15.05,15.05,0,0,1,4.51-5.71,9.85,9.85,0,0,1,6-2.07v10.24Z"
          transform="translate(0)"
        />
      </g>

      <polyline
        id="Trace1"
        className="cls2-3"
        points="1025.27 56.12 1025.28 237 628.28 237"
      />

      <polyline
        id="Trace1Ombre"
        className="cls2-4 OmbreAcceuil"
        points="1025.27 56.12 1025.28 237 628.28 237"
      />

      <polyline
        id="Trace2"
        className="cls2-3"
        points="215.28 237 102.28 237 102.28 3 940.4 3"
      />

      <polyline
        id="Trace2Ombre"
        className="cls2-4 OmbreAcceuil"
        points="215.28 237 102.28 237 102.28 3 940.4 3"
      />
    </svg>
  );
}
