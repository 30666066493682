import React, { useState, useEffect } from "react";
import "./sections.css";
import Gallery from "react-photo-gallery";
import gsap from "gsap";

import $ from "jquery";
import "./Creations.css";
import GalleryGameSVG from "../svg/GalleryGameSVG";
import GalleryDesignSVG from "../svg/GalleryDesignSVG";
import GalleryWebSVG from "../svg/GalleryWebSVG";
import GalleryAllSVG from "../svg/GalleryAllSVG";
import { I18nProvider } from "../../i18n";
import translate from "../../i18n/translate";
import CreationsModals from "../CreationsModals";

let firstChange = true;
let designArray = [
  {
    src: "creations-gallery/Chimere.jpg",
    width: 2,
    height: 2,
  },
  {
    src: "creations-gallery/anamorphose.png",
    width: 1,
    height: 1,
  },
  {
    src: "creations-gallery/Detournement.jpg",
    width: 3,
    height: 2,
  },
];
const webArray = [
  {
    src: "creations-gallery/CCAPhoto.PNG",
    width: 50,
    height: 28,
  },
  // {
  //   src: "creations-gallery/Moissey.png",
  //   width: 21,
  //   height: 28,
  // },
  {
    src: "creations-gallery/laboite.png",
    width: 3,
    height: 2,
  },
  {
    src: "creations-gallery/mezig.png",
    width: 1,
    height: 1,
  },
  {
    src: "creations-gallery/handicapSco.png",
    width: 1,
    height: 1,
  },
];
const gameArray = [
  {
    src: "creations-gallery/geometry.png",
    width: 1,
    height: 1,
  },
  {
    src: "creations-gallery/pong.png",
    width: 2,
    height: 1,
  },
  // {
  //   src: "creations-gallery/flappy.png",
  //   width: 3,
  //   height: 5,
  // },
];
let allArray = gameArray.concat(webArray);
allArray = allArray.concat(designArray);
shuffle(allArray);
function shuffle(array) {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export default function Creations({ isMobile, locale }) {
  const [gallery, setGallery] = useState([]);
  const [clicked, setClicked] = useState("");
  const [creationOpened, setCreationOpened] = useState(false);

  const handleCloseCreationOpened = () => {
    setClicked(false);
    setCreationOpened(false);
  };

  const changeGallery = (div, array) => {
    if (div.attr("class") === "active") {
    } else {
      if (firstChange) {
        if (array !== allArray) {
          setGallery(allArray);
        } else {
          setGallery(designArray);
        }
        div.toggleClass("active");
        $("#gallery").css("display", "block");
        gsap
          .timeline({
            onComplete: () => {
              firstChange = false;

              setGallery(array);
            },
          })
          .to("#buttonGallery", {
            transform: "rotate(0deg) translateY(0%) translateX(0%)",
            duration: 1,
          })
          .to("#buttonGallery>div", {
            transform: "rotate(0deg)",
            duration: 1,
            delay: -1,
          })
          .to("#buttonGallery>div>span", {
            height: "12vmin",
            width: "12vmin",
            duration: 0.7,
            delay: -1,
          })
          .to("#gallery", {
            opacity: 1,
            ease: "power2.easeInOut",
            duration: 0.5,
          });
      } else {
        $("span.active").toggleClass("active");
        div.toggleClass("active");
        gsap
          .timeline({})
          .to("#gallery", {
            opacity: 0,
            ease: "power2.easeInOut",
            duration: 0.5,
            onComplete: () => {
              setGallery(array);
            },
          })
          .to("#gallery", {
            ease: "power2.easeInOut",
            opacity: 1,
            delay: 0.3,
            duration: 0.5,
          });
      }
    }
  };

  $(".react-photo-gallery--gallery").on("click", function (e) {
    let clickedName;
    console.log(e.target.src);
    if (e.target.src !== undefined) {
      let parts = e.target.src.split("/");
      clickedName = parts[parts.length - 1];
    }
    setClicked(clickedName);
    setCreationOpened(true);
  });

  return (
    <div id="creations" className="section">
      <div id="buttonGallery">
        <div id="designSelector">
          <span
            id="spanDesign"
            onClick={() => {
              changeGallery($("#spanDesign"), designArray);
            }}
          >
            <GalleryDesignSVG />
          </span>
          <p>
            <I18nProvider locale={locale}>
              {translate("creationButtonDesign")}
            </I18nProvider>
          </p>
        </div>
        <div id="webSelector">
          <span
            id="spanWeb"
            onClick={() => {
              changeGallery($("#spanWeb"), webArray);
            }}
          >
            <GalleryWebSVG />
          </span>
          <p>
            <I18nProvider locale={locale}>
              {translate("creationButtonWeb")}
            </I18nProvider>
          </p>
        </div>
        <div id="gameSelector">
          <span
            id="spanGame"
            onClick={() => {
              changeGallery($("#spanGame"), gameArray);
            }}
          >
            <GalleryGameSVG />
          </span>
          <p>
            <I18nProvider locale={locale}>
              {translate("creationButtonGame")}
            </I18nProvider>
          </p>
        </div>
        <div id="allSelector">
          <span
            id="spanAll"
            onClick={() => {
              changeGallery($("#spanAll"), allArray);
            }}
          >
            <GalleryAllSVG />
          </span>
          <p>
            <I18nProvider locale={locale}>
              {translate("creationButtonAll")}
            </I18nProvider>
          </p>
        </div>
      </div>
      <div id="gallery">
        <Gallery photos={gallery} direction={"column"} />
      </div>
      <CreationsModals
        isMobile={isMobile}
        locale={locale}
        creationOpened={creationOpened}
        handleCloseCreationOpened={handleCloseCreationOpened}
        clicked={clicked}
      />
    </div>
  );
}
