import React from "react";
import { HashRouter , Route } from "react-router-dom";
import "./App.css";
import Ollicsom from "./screens/Ollicsom.jsx";
import Portfolio from "./screens/Portfolio";
import Study from "./screens/Study";

//import { FormattedMessage } from 'react-intl';

function App() {     

  return (
    <div className="Portfolio">
      <HashRouter >
        <Route exact path="/" component={Ollicsom} />
        <Route path="/Portfolio" component={Portfolio} />
        <Route path="/Studies" component={Study} />
      </HashRouter >
    </div>
  );
}

export default App;
