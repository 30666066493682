import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import $ from "jquery";
import * as PIXI from "pixi.js";
import "../svg/CourbeAbout.css";

import { I18nProvider } from "../../i18n";
import translate from "../../i18n/translate";

const useStyles = (isMobile) =>
  makeStyles(() => ({
    card: {
      width: "94vw",
      height: "94vh",
      backgroundColor: "#f0f2f5",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "3%",
    },
    closeIcon: {
      position: "fixed",
      top: "4vh",
      right: "3vw",
      cursor: "pointer",
      fontSize: "3.5vmin",
    },
  }));

export default function Pong({
  isMobile,
  locale,
  creationOpened,
  handleCloseCreationOpened,
}) {
  useEffect(() => {
    // Dimensions de la scène
    var wST, hST;
    // Dimensions raquettes, balle
    var sizeRaquette = { w: 15, h: 60 };
    var rayonBalle = 8;

    // Référence la scène, la raquette gauche (rG), droite (rD) et la balle
    var stage, rG, rD, balle, rGU, rGD, rDU, rDD;
    // Vecteur pour déplacer la balle sur les deux axes (x et y)
    var v = { x: 0, y: 0 };
    // Vitesse de déplacement Y des raquettes
    var vR = 4;
    // Nombre de points joueur guache (ptG), joueur droit (ptD)
    var ScoreP1 = 0;
    var ScoreP2 = 0;

    let app;
    let AngleRad, Angle;
    let Score1, Score2;

    $(document).ready(function () {
      wST = 900;
      hST = 480;
      console.log(wST, hST);
      initApp();
    });

    // Fonction pour initialiser l'application PIXI
    // Dessiner les éléments, mettre en place les événements et la gameloop
    function initApp() {
      // Création de l'application PIXI
      app = new PIXI.Application({
        width: wST,
        height: hST,
        backgroundColor: 0x333333,
        resolution: 1,
        antialias: true,
      });
      // Ajout du noeud canvas généré par PIXI au sein au DOM dans la balise 'body'
      $("#pong").append(app.view);

      // Référence le stage
      stage = app.stage;

      // Dessine les éléments
      drawElts();

      // Initialise les événements
      initEvents();

      // Généré un angle aléatoire compris entre -30° et 30°
      Angle = Math.random() * (30 - -30) + -30;
      // Transformez cet angle en radians
      AngleRad = (Angle * Math.PI) / 180;
      // Transformez cet angle pour qu'il vous donne un vecteur x/y (voir Math.cos()/Math.sin()). Stockez le résultat obtenu dans 'v'
      v.x = Math.cos(AngleRad) * 5;
      v.y = Math.sin(AngleRad) * 5;

      // A l'aide d'un nombre aléatoire, si la valeur générée est inférieure à 0.5, inversez le sens de X pour qu'il devienne négatif
      if (Math.random() < 0.5) {
        v.x = -v.x;
      }

      // Gameloop
      app.ticker.add((delta) => {
        gameloop();
      });
    }

    // Fonction pour dessiner les éléments
    function drawElts() {
      // A l'aide de la classe Graphics de PIXI, dessinez un rectangle en vous servant de 'sizeRaquette'
      // Stockez ce rectangle dans la variable 'rG'
      // Positionnez cette raquette à 15 pixels du bord gauche
      // Elle doit être centrée verticalement

      rG = new PIXI.Graphics();
      rG.beginFill(0x87ceeb);
      rG.drawRect(0, 0, sizeRaquette.w, sizeRaquette.h);
      rG.x = 15;
      rG.y = hST / 2 - 0.5 * sizeRaquette.h;
      stage.addChild(rG);

      // A l'aide de la classe Graphics de PIXI, dessinez un rectangle en vous servant de 'sizeRaquette'
      // Stockez ce rectangle dans la variable 'rD'
      // Positionnez cette raquette à 15 pixels du bord droit
      // Elle doit être centrée verticalement

      rD = new PIXI.Graphics();
      rD.beginFill(0x87ceeb);
      rD.drawRect(0, 0, sizeRaquette.w, sizeRaquette.h);
      rD.x = wST - 15 - sizeRaquette.w;
      rD.y = hST / 2 - 0.5 * sizeRaquette.h;
      stage.addChild(rD);

      // A l'aide de la classe Graphics de PIXI, dessinez un cercle en vous servant de 'rayonBalle'
      // Stockez ce rectangle dans la variable 'balle'
      // Positionnez la balle au centre de l'écran

      balle = new PIXI.Graphics();
      balle.beginFill(0xfffff0);
      balle.lineStyle(0);
      balle.drawCircle(0, 0, rayonBalle);
      balle.y = hST / 2 - rayonBalle / 2;
      balle.x = wST / 2 - rayonBalle / 2;
      balle.endFill();
      stage.addChild(balle);

      Score1 = new PIXI.Text(ScoreP1, {
        fontFamily: "Arial",
        fontSize: 35,
        fill: 0xffffff,
        align: "center",
      });
      Score1.x = wST / 2 - Score1.width - 30;
      Score1.y = hST / 2 - Score1.height / 2;
      app.stage.addChild(Score1);

      Score2 = new PIXI.Text(ScoreP2, {
        fontFamily: "Arial",
        fontSize: 35,
        fill: 0xffffff,
        align: "center",
      });
      Score2.x = wST / 2 + Score1.width + 30;
      Score2.y = hST / 2 - Score1.height / 2;
      app.stage.addChild(Score2);
    }

    // Fonction pour mettre en place les événments
    function initEvents() {
      // Mettez en place un écouteur d'événement pour récupérer les événéments de clavier
      // Dans la fonction appelée par 'événement de clavier
      // Si la touche appuyée est A, stocke une information pour indiquer que la raquette gauche monte
      // Si la touche appuyée est Q, stocke une information pour indiquer que la raquette gauche descend
      // Si la touche appuyée est Fléche Haut, stocke une information pour indiquer que la raquette droite monte
      // Si la touche appuyée est Flèche Bas, stocke une information pour indiquer que la raquette droite descend

      document.addEventListener("keydown", function (event) {
        if (event.key === "ArrowUp") {
          rDU = true;
        }
        if (event.key === "ArrowDown") {
          rDD = true;
        }
        if (event.key === "a") {
          rGU = true;
        }
        if (event.key === "q") {
          rGD = true;
        }
      });

      document.addEventListener("keyup", function (event) {
        if (event.key === "ArrowUp") {
          rDU = false;
        }
        if (event.key === "ArrowDown") {
          rDD = false;
        }
        if (event.key === "a") {
          rGU = false;
        }
        if (event.key === "q") {
          rGD = false;
        }
      });
    }

    // Fonction pour gérer les dépalcements, collisions, etc
    function gameloop() {
      // Déplacement des raquettes verticalement (sens de déplacement et vitesse déplacement 'vR')
      // Les raquettes ne peuvent pas sortir de la scène

      if (rDU === true && rD.y > 0) {
        rD.y -= vR;
      }

      if (rDD === true && rD.y < hST - sizeRaquette.h) {
        rD.y += vR;
      }

      if (rGU === true && rG.y > 0) {
        rG.y -= vR;
      }

      if (rGD === true && rG.y < hST - sizeRaquette.h) {
        rG.y += vR;
      }

      // Déplacement de la balle sur les deux axes 'v'

      balle.y += v.y;
      balle.x += v.x;

      // Si la balle toucke le haut ou le bas de la scène, faire en sorte qu'elle rebondisse
      // Une simple vérification de position suffit

      if (balle.y <= 0) {
        v.y = -v.y;
      }
      if (balle.y >= hST) {
        v.y = -v.y;
      }

      // Si la balle entre en collision avec les raquettes, elle rebondit également
      // Utilisez la fonction pour détecter les collisions ci-dessous

      if (collision(rG, balle)) {
        v.x = -v.x;
      }
      if (collision(rD, balle)) {
        v.x = -v.x;
      }

      // Si la balle sort par la gauche ou la droite
      // Marque un point (joueur droit ou gauche selon)
      // Replace la balle au centre de la scène
      // Génère deux nombres aléatoires pour le déplacement de la balle 'v' (même opération que dans initApp())

      if (balle.x <= 0) {
        ScoreP2++;
        Score2.text = ScoreP2;
        RemiseEnJeu();
      }
      if (balle.x >= wST) {
        ScoreP1++;
        Score1.text = ScoreP1;
        RemiseEnJeu();
      }

      function RemiseEnJeu() {
        Angle = Math.random() * (30 - -30) + -30;
        AngleRad = (Angle * Math.PI) / 180;
        v.x = Math.cos(AngleRad) * 5;
        v.y = Math.sin(AngleRad) * 5;
        if (Math.random() < 0.5) {
          v.x = -v.x;
        }
        balle.y = hST / 2 - rayonBalle / 2;
        balle.x = wST / 2 - rayonBalle / 2;
      }
    }

    function collision(r1, r2) {
      var r1Tmp = r1.getLocalBounds();
      var r2Tmp = r2.getLocalBounds();
      var c1 = r1.getGlobalPosition();
      var c2 = r2.getGlobalPosition();
      r1Tmp.x = c1.x; // - r1Tmp.width / 2;
      r1Tmp.y = c1.y; // - r1Tmp.height / 2;
      r2Tmp.x = c2.x;
      r2Tmp.y = c2.y;

      var test1 = r1Tmp.x + r1Tmp.width < r2Tmp.x - rayonBalle;
      var test2 = r1Tmp.x > r2Tmp.x + r2Tmp.width - rayonBalle;
      var test3 = r1Tmp.y + r1Tmp.height < r2Tmp.y;
      var test4 = r1Tmp.y > r2Tmp.y + r2Tmp.height;
      return !(test1 || test2 || test3 || test4);
    }
  }, []);

  const classes = useStyles(isMobile)();

  return (
    <div>
      <Modal open={creationOpened} onClose={handleCloseCreationOpened}>
        <Card className={classes.card}>
          <CloseIcon
            className={classes.closeIcon}
            onClick={handleCloseCreationOpened}
          >
            X
          </CloseIcon>
          <Typography gutterBottom variant="h3" component="h4">
            <I18nProvider locale={locale}>
              {translate("creationTitlePong")}
            </I18nProvider>
          </Typography>
          <div id="pong"></div>
          <Typography gutterBottom variant="body1" component="p">
            <I18nProvider locale={locale}>
              {translate("creationControlsPong")}
            </I18nProvider>
            <br />
            <I18nProvider locale={locale}>
              {translate("creationControlsPong2")}
            </I18nProvider>
          </Typography>
          <Typography gutterBottom variant="body2" component="p">
            <I18nProvider locale={locale}>
              {translate("creationBodyPong")}
            </I18nProvider>
          </Typography>
        </Card>
      </Modal>
    </div>
  );
}
