import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";

import { I18nProvider } from "../i18n";
import translate from "../i18n/translate";

import "./svg/CourbeAbout.css";

const useStyles = (isMobile) =>
  makeStyles(() => ({
    card: {
      width: isMobile ? "90vw" : "40vmin",
      height: "40vh",
      borderRadius: "4px",
      margin: "10px",
      justifySelf: "flex-end",
      boxShadow: "0 12px 25px 0 rgb(199 175 189 / 25%)",
    },
    modal: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    image: {
      height: "auto",
      width: "100%",
      minHeight: "180px",
      maxHeight: "200px",
    },
    text: {
      color: "white",
      margin: "30px",
      marginLeft: "30px",
      marginRight: "40px",
    },
  }));

export default function AllModals({
  isMobile,
  locale,
  openBac,
  handleCloseBac,
  openDUT,
  handleCloseDUT,
  openLicence,
  handleCloseLicence,
  openSandwich,
  handleCloseSandwich,
}) {
  const classes = useStyles(isMobile)();

  return (
    <div>
      <Modal className={classes.modal} open={openBac} onClose={handleCloseBac}>
        <Grow in={openBac}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.image}
              image="./charles-poncet.jpg"
              title="Lycée Charle Poncet"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                <I18nProvider locale={locale}>
                  {translate("modalTitleBac")}
                </I18nProvider>
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <I18nProvider locale={locale}>
                  {translate("modalDescBac")}
                </I18nProvider>
              </Typography>
            </CardContent>
            {/* <CardActions>
              <Button onClick={() => {window.location = "/Studies"}} size="small" color="primary">
                <I18nProvider locale={locale}>
                  {translate("modalLearn")}
                </I18nProvider>
              </Button>
            </CardActions> */}
          </Card>
        </Grow>
      </Modal>
      <Modal className={classes.modal} open={openDUT} onClose={handleCloseDUT}>
        <Grow in={openDUT}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.image}
              image="./iut-dijon.jpg"
              title="Lycée Charle Poncet"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                <I18nProvider locale={locale}>
                  {translate("modalTitleDUT")}
                </I18nProvider>
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <I18nProvider locale={locale}>
                  {translate("modalDescDUT")}
                </I18nProvider>
              </Typography>
            </CardContent>
            {/* <CardActions>
              <Button  onClick={() => {window.location = "/Studies"}} size="small" color="primary">
                <I18nProvider locale={locale}>
                  {translate("modalLearn")}
                </I18nProvider>
              </Button>
            </CardActions> */}
          </Card>
        </Grow>
      </Modal>
      <Modal
        className={classes.modal}
        open={openLicence}
        onClose={handleCloseLicence}
      >
        <Grow in={openLicence}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.image}
              image="./licence.png"
              title="Lycée Charle Poncet"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                <I18nProvider locale={locale}>
                  {translate("modalTitleLicence")}
                </I18nProvider>
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <I18nProvider locale={locale}>
                  {translate("modalDescLicence")}
                </I18nProvider>
              </Typography>
            </CardContent>
            {/* <CardActions>
              <Button  onClick={() => {window.location = "/Studies"}} size="small" color="primary">
                <I18nProvider locale={locale}>
                  {translate("modalLearn")}
                </I18nProvider>
              </Button>
            </CardActions> */}
          </Card>
        </Grow>
      </Modal>
      <Modal
        className={classes.modal}
        open={openSandwich}
        onClose={handleCloseSandwich}
      >
        <Grow in={openSandwich}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.image}
              image="./rectorat.jpg"
              title="Lycée Charle Poncet"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                <I18nProvider locale={locale}>
                  {translate("modalTitleSandwich")}
                </I18nProvider>
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <I18nProvider locale={locale}>
                  {translate("modalDescSandwich")}
                </I18nProvider>
              </Typography>
            </CardContent>
            {/* <CardActions>
              <Button  onClick={() => {window.location = "/Studies"}} size="small" color="primary">
                <I18nProvider locale={locale}>
                  {translate("modalLearn")}
                </I18nProvider>
              </Button>
            </CardActions> */}
          </Card>
        </Grow>
      </Modal>
    </div>
  );
}
