import { LOCALES } from "../locales"

export default {
    [LOCALES.ENGLISH]: {
        'leo': 'Leo MOSCILLO',

        'menuHome': 'Home',
        'menuAbout': 'About',
        'menuCreations': 'My Creations',
        'menuContact': 'Contact',
        'menuLanguage': 'Language',

        'aboutHello': 'Hello,',
        'aboutDesc1.2': "I'm Leo, {age} years old and",
        
        'aboutDesc0.1': "I'm Leo, {age} years old and working",
        'aboutDesc0.2': "with {entreprise} as a web developer",
        'aboutDesc1.1': "I'm Leo, {age} years old and searching for",
        'aboutDesc1.2': "a job in the world of web-development",
        'aboutDesc2.1': "I'm Leo, {age} years old , freelancer",
        'aboutDesc2.2': "and searching for mission in the field of web-development",
        'circleBac' : "Baccalaureate",
        'circleDUT' : "Higher National Diploma",
        'circleLicence' : "Licence",
        'circleSandwich' : "Sandwich",

        'modalLearn': "Learn more...",
        'modalTitleBac': "Baccalaureate",
        'modalDescBac': "In 2018, I got my baccalaureate STI2D SIN with distinction in Lycée Charles Poncet, Cluses 74300",
        'modalTitleDUT': "Higher National Diploma",
        'modalDescDUT': "In 2020, I completed my HND about mutimedias design, development and branding, in Dijon.",
        'modalTitleLicence': "Professional licence",
        'modalDescLicence': "After my Higher National Diploma, I made a licence in the field of mobile design and development",
        'modalTitleSandwich': "Sandwich degree",
        'modalDescSandwich': "During my licence, I was also working at Dijon's rectorate, developing some apps for National Education",

        'creationButtonDesign': "Design",
        'creationButtonWeb': "Web",
        'creationButtonGame': "Games",
        'creationButtonAll': "Everything",

        'contactResume': 'Find my resume here :',
        'contactLegal': 'Legal notice',
        
        'creationTitleGeometry': 'Geometry Dash',

        'copy' : 'E-mail has been copied.',
    }
}