import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Carousel from "react-material-ui-carousel";

import "../svg/CourbeAbout.css";

import { I18nProvider } from "../../i18n";
import translate from "../../i18n/translate";

const useStyles = (isMobile) =>
  makeStyles(() => ({
    card: {
      width: "94vw",
      height: "94vh",
      backgroundColor: "#f0f2f5",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "3%",
    },
    closeIcon: {
      position: "fixed",
      top: "4vh",
      right: "3vw",
      cursor: "pointer",
      fontSize: "3.5vmin",
    },
    minimizedWidth: {
      width: "65%",
    },
    media: {
      height: "50vmin",
      width: "88vmin",
      marginBottom: "3%",
    },
  }));

export default function Anamorphose({
  isMobile,
  locale,
  creationOpened,
  handleCloseCreationOpened,
}) {
  useEffect(() => {});

  var items = [
    {
      url: "creations-gallery/sliderAnamorphose/1.png",
      title: (
        <I18nProvider locale={locale}>
          {translate("creationSlideAnamorphose1")}
        </I18nProvider>
      ),
    },
    {
      url: "creations-gallery/sliderAnamorphose/2.png",
      title: (
        <I18nProvider locale={locale}>
          {translate("creationSlideAnamorphose2")}
        </I18nProvider>
      ),
    },
    {
      url: "creations-gallery/sliderAnamorphose/3.png",
      title: (
        <I18nProvider locale={locale}>
          {translate("creationSlideAnamorphose3")}
        </I18nProvider>
      ),
    },
    {
      url: "creations-gallery/sliderAnamorphose/4.png",
      title: (
        <I18nProvider locale={locale}>
          {translate("creationSlideAnamorphose4")}
        </I18nProvider>
      ),
    },
    {
      url: "creations-gallery/sliderAnamorphose/5.png",
      title: (
        <I18nProvider locale={locale}>
          {translate("creationSlideAnamorphose5")}
        </I18nProvider>
      ),
    },
    {
      url: "creations-gallery/sliderAnamorphose/6.png",
      title: (
        <I18nProvider locale={locale}>
          {translate("creationSlideAnamorphose6")}
        </I18nProvider>
      ),
    },
    {
      url: "creations-gallery/sliderAnamorphose/7.png",
      title: (
        <I18nProvider locale={locale}>
          {translate("creationSlideAnamorphose7")}
        </I18nProvider>
      ),
    },
    {
      url: "creations-gallery/sliderAnamorphose/8.png",
      title: (
        <I18nProvider locale={locale}>
          {translate("creationSlideAnamorphose8")}
        </I18nProvider>
      ),
    },
  ];

  function Item(props) {
    return (
      <div>
        <CardMedia
          className={classes.media}
          image={props.item.url}
          title={props.item.title}
        />
        <Typography align="center" gutterBottom variant="body1" component="p">
          {props.item.title}
        </Typography>
      </div>
    );
  }

  const classes = useStyles(isMobile)();

  return (
    <div>
      <Modal open={creationOpened} onClose={handleCloseCreationOpened}>
        <Card className={classes.card}>
          <CloseIcon
            className={classes.closeIcon}
            onClick={handleCloseCreationOpened}
          >
            X
          </CloseIcon>
          <Typography gutterBottom variant="h3" component="h2">
            <I18nProvider locale={locale}>
              {translate("creationTitleAnamorphose")}
            </I18nProvider>
          </Typography>
          <Carousel animation="slide" autoPlay="true">
            {items.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Carousel>
          <Typography
            className={classes.minimizedWidth}
            gutterBottom
            variant="body1"
            component="p"
          >
            <I18nProvider locale={locale}>
              {translate("creationSummaryAnamorphose")}
            </I18nProvider>
          </Typography>
          <Typography
            className={classes.minimizedWidth}
            gutterBottom
            variant="body1"
            component="p"
          >
            <I18nProvider locale={locale}>
              {translate("creationBodyAnamorphose")}
            </I18nProvider>
          </Typography>
        </Card>
      </Modal>
    </div>
  );
}
