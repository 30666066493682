import './Study.css';

export default function Study() {

  return (
      <div id='study'>
          <section id='study-content'>
            <a href='/Portfolio#about'>Go back</a>
          </section>
      </div>
  );
}
