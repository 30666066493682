import React from "react";
import GeometryDash from "./Creations/GeometryDash";
import Pong from "./Creations/Pong";
import FlappyBird from "./Creations/FlappyBird";
import Mezig from "./Creations/Mezig";
import Laboite from "./Creations/Laboite";
import CCAPhoto from "./Creations/CCAPhoto";
import HandicapsSco from "./Creations/Handicaps&Sco";
import Chimere from "./Creations/Chimere";
import Anamorphose from "./Creations/Anamorphose";
import Detournement from "./Creations/Detournement";
import "./svg/CourbeAbout.css";

export default function CreationsModals({
  isMobile,
  locale,
  creationOpened,
  handleCloseCreationOpened,
  clicked,
}) {
  return (
    <div>
      {
        {
          "geometry.png": (
            <GeometryDash
              isMobile={isMobile}
              locale={locale}
              creationOpened={creationOpened}
              handleCloseCreationOpened={handleCloseCreationOpened}
            />
          ),
          "pong.png": (
            <Pong
              isMobile={isMobile}
              locale={locale}
              creationOpened={creationOpened}
              handleCloseCreationOpened={handleCloseCreationOpened}
            />
          ),
          "flappy.png": (
            <FlappyBird
              isMobile={isMobile}
              locale={locale}
              creationOpened={creationOpened}
              handleCloseCreationOpened={handleCloseCreationOpened}
            />
          ),
          "mezig.png": (
            <Mezig
              isMobile={isMobile}
              locale={locale}
              creationOpened={creationOpened}
              handleCloseCreationOpened={handleCloseCreationOpened}
            />
          ),
          "laboite.png": (
            <Laboite
              isMobile={isMobile}
              locale={locale}
              creationOpened={creationOpened}
              handleCloseCreationOpened={handleCloseCreationOpened}
            />
          ),
          "CCAPhoto.PNG": (
            <CCAPhoto
              isMobile={isMobile}
              locale={locale}
              creationOpened={creationOpened}
              handleCloseCreationOpened={handleCloseCreationOpened}
            />
          ),
          "handicapSco.png": (
            <HandicapsSco
              isMobile={isMobile}
              locale={locale}
              creationOpened={creationOpened}
              handleCloseCreationOpened={handleCloseCreationOpened}
            />
          ),
          "Chimere.jpg": (
            <Chimere
              isMobile={isMobile}
              locale={locale}
              creationOpened={creationOpened}
              handleCloseCreationOpened={handleCloseCreationOpened}
            />
          ),
          "anamorphose.png": (
            <Anamorphose
              isMobile={isMobile}
              locale={locale}
              creationOpened={creationOpened}
              handleCloseCreationOpened={handleCloseCreationOpened}
            />
          ),
          "Detournement.jpg": (
            <Detournement
              isMobile={isMobile}
              locale={locale}
              creationOpened={creationOpened}
              handleCloseCreationOpened={handleCloseCreationOpened}
            />
          ),
        }[clicked]
      }
    </div>
  );
}
