import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import $ from "jquery";
import * as PIXI from "pixi.js";
import "../svg/CourbeAbout.css";

import { I18nProvider } from "../../i18n";
import translate from "../../i18n/translate";

const useStyles = (isMobile) =>
  makeStyles(() => ({
    card: {
      width: "94vw",
      height: "94vh",
      backgroundColor: "#f0f2f5",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "3%",
    },
    closeIcon: {
      position: "fixed",
      top: "4vh",
      right: "3vw",
      cursor: "pointer",
      fontSize: "3.5vmin",
    },
    text: {
      fontFamily: "Bahnschrift Regular",
    },
    controls: {
      marginBottom: "5vh",
      fontFamily: "Bahnschrift Regular",
    },
  }));

export default function GeometryDash({
  isMobile,
  locale,
  creationOpened,
  handleCloseCreationOpened,
}) {
  useEffect(() => {
    var stage;
    var app;
    var wST, hST;
    var levelActu = 0;
    var hP = 20;
    var wP = 20;
    var player;
    var NbTry = 0;
    var vY = 0.0,
      impulsion = -15,
      accY = 1;
    var allowJump = true;
    var Tbloc = [];
    var levels = [
      {
        level: 1,
        nbPoints: 10,
        nbTentatives: 1,
        couleurFd: "FFDD00",
        couleurBlocs: "FFBB00",
        couleurPerso: "FF5555",
        blocs: [
          {
            x: 400,
            y: "b",
            w: 28,
            h: 50,
          },
          {
            x: 570,
            y: "b",
            w: 28,
            h: 50,
          },
          {
            x: 600,
            y: "h",
            w: 28,
            h: 150,
          },
        ],
      },
      {
        level: 2,
        nbPoints: 20,
        nbTentatives: 2,
        couleurFd: "DDFF00",
        couleurBlocs: "BBFF00",
        couleurPerso: "55FF55",
        blocs: [
          {
            x: 300,
            y: "b",
            w: 50,
            h: 50,
          },
          {
            x: 500,
            y: "b",
            w: 28,
            h: 50,
          },
          {
            x: 600,
            y: "h",
            w: 28,
            h: 180,
          },
          {
            x: 700,
            y: "b",
            w: 56,
            h: 25,
          },
        ],
      },
      {
        level: 3,
        nbPoints: 30,
        nbTentatives: 3,
        couleurFd: "ed6339",
        couleurBlocs: "853720",
        couleurPerso: "b56750",
        blocs: [
          {
            x: 200,
            y: "b",
            w: 28,
            h: 50,
          },
          {
            x: 335,
            y: "b",
            w: 28,
            h: 50,
          },
          {
            x: 470,
            y: "b",
            w: 28,
            h: 50,
          },
          {
            x: 600,
            y: "h",
            w: 28,
            h: 250,
          },
          {
            x: 720,
            y: "b",
            w: 28,
            h: 60,
          },
        ],
      },
    ];
    var couleurFond;
    var couleurPlafond;
    var couleurSol;
    var couleurBlocs;
    var couleurPlayer;

    $(document).ready(function () {
      wST = 900;
      hST = 480;

      initApp();
    });

    function initApp() {
      app = new PIXI.Application({
        width: wST,
        height: hST,
        resolution: 1,
        antialias: true,
      });
      $("#geometryDash").append(app.view);
      stage = app.stage;

      drawElts();

      initEvents();

      app.ticker.add((delta) => {
        gameloop();
      });
    }

    function drawElts() {
      CreateFond();
      function CreateFond() {
        couleurFond = levels[levelActu].couleurFd;
        couleurPlafond = levels[levelActu].couleurBlocs;
        couleurSol = levels[levelActu].couleurBlocs;
        couleurBlocs = levels[levelActu].couleurBlocs;
        var fond;
        var sol;
        var plafond;

        if (fond) {
          fond.clear();
        }
        if (sol) {
          sol.clear();
        }
        if (plafond) {
          plafond.clear();
        }
        fond = new PIXI.Graphics();
        fond.beginFill("0x" + couleurFond);
        fond.drawRect(0, 0, wST, hST);
        fond.x = 0;
        fond.y = 0;
        stage.addChild(fond);

        sol = new PIXI.Graphics();
        sol.beginFill("0x" + couleurSol);
        sol.drawRect(0, 0, wST, 100);
        sol.x = 0;
        sol.y = 0;
        stage.addChild(sol);

        plafond = new PIXI.Graphics();
        plafond.beginFill("0x" + couleurPlafond);
        plafond.drawRect(0, 0, wST, 100);
        plafond.x = 0;
        plafond.y = hST - 100;
        stage.addChild(plafond);
      }

      CreateBlocs();
      function CreateBlocs() {
        Tbloc = [];

        for (var i = 0, bloc, b; i < levels[levelActu].blocs.length; i++) {
          b = levels[levelActu].blocs[i];

          bloc = new PIXI.Graphics();
          bloc.beginFill("0x" + couleurBlocs);
          bloc.drawRect(0, 0, b.w, b.h);
          bloc.x = b.x;
          if (b.y === "b") {
            bloc.y = hST - 100 - b.h;
          } else {
            bloc.y = 100;
          }
          stage.addChild(bloc);
          Tbloc.push(bloc);
        }
      }

      CreateJoueur();
      function CreateJoueur() {
        couleurPlayer = levels[levelActu].couleurPerso;

        if (player) {
          player.clear();
        }
        player = new PIXI.Graphics();
        player.beginFill("0x" + couleurPlayer);
        player.drawRect(-wP / 2, -hP / 2, wP, hP);
        player.x = wP / 2;
        player.y = hST - hP / 2 - 100;
        stage.addChild(player);
      }
    }

    // Fonction pour mettre en place les événments
    function initEvents() {
      document.addEventListener("keydown", onKeyDown);

      function onKeyDown(e) {
        if (e.keyCode === 32 && allowJump) {
          vY = impulsion;
          allowJump = false;
        }
      }
    }

    function gameloop() {
      player.x += 4;

      for (var i = 0; i < Tbloc.length; i++) {
        if (collisionRectangles(player, Tbloc[i])) {
          player.x = 0;
          NbTry++;
        }
      }

      player.y += vY;
      vY += accY;
      player.rotation = vY * 0.1;
      if (player.y >= hST - hP / 2 - 100) {
        vY = 0;
        player.rotation = 0.0;
      }
      if (player.y === hST - 100 - hP / 2) {
        allowJump = true;
      }

      if (player.x >= wST) {
        levels[levelActu].nbPoints =
          levels[levelActu].nbPoints - NbTry + levels[levelActu].nbTentatives;
        NbTry = 0;
        levelActu++;
        if (levelActu > levels.length - 1) {
          levelActu = 0;
        }
        drawElts();
        player.x = wP / 2;
      }
    }

    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    // COLLISIONS

    function collisionRectangles(r1, r2) {
      var r1p1x = r1.x,
        r1p1y = r1.y,
        r1p2x = r1.x + r1.width,
        r1p2y = r1.y;
      var r1p3x = r1.x + r1.width,
        r1p3y = r1.y + r1.height,
        r1p4x = r1.x,
        r1p4y = r1.y + r1.height;
      var r2p1x = r2.x,
        r2p1y = r2.y,
        r2p2x = r2.x + r2.width,
        r2p2y = r2.y;
      var r2p3x = r2.x + r2.width,
        r2p3y = r2.y + r2.height,
        r2p4x = r2.x,
        r2p4y = r2.y + r2.height;
      if (
        !isProjectedAxisCollision(
          r1p1x,
          r1p1y,
          r1p2x,
          r1p2y,
          r2p1x,
          r2p1y,
          r2p2x,
          r2p2y,
          r2p3x,
          r2p3y,
          r2p4x,
          r2p4y
        )
      ) {
        return false;
      }
      if (
        !isProjectedAxisCollision(
          r1p2x,
          r1p2y,
          r1p3x,
          r1p3y,
          r2p1x,
          r2p1y,
          r2p2x,
          r2p2y,
          r2p3x,
          r2p3y,
          r2p4x,
          r2p4y
        )
      ) {
        return false;
      }
      if (
        !isProjectedAxisCollision(
          r2p1x,
          r2p1y,
          r2p2x,
          r2p2y,
          r1p1x,
          r1p1y,
          r1p2x,
          r1p2y,
          r1p3x,
          r1p3y,
          r1p4x,
          r1p4y
        )
      ) {
        return false;
      }
      if (
        !isProjectedAxisCollision(
          r2p2x,
          r2p2y,
          r2p3x,
          r2p3y,
          r1p1x,
          r1p1y,
          r1p2x,
          r1p2y,
          r1p3x,
          r1p3y,
          r1p4x,
          r1p4y
        )
      ) {
        return false;
      }
      return true;
    }
    function isProjectedAxisCollision(
      b1x,
      b1y,
      b2x,
      b2y,
      p1x,
      p1y,
      p2x,
      p2y,
      p3x,
      p3y,
      p4x,
      p4y
    ) {
      var x1, x2, x3, x4;
      var y1, y2, y3, y4;
      if (b1x === b2x) {
        x1 = x2 = x3 = x4 = b1x;
        y1 = p1y;
        y2 = p2y;
        y3 = p3y;
        y4 = p4y;
        if (b1y > b2y) {
          if (
            (y1 > b1y && y2 > b1y && y3 > b1y && y4 > b1y) ||
            (y1 < b2y && y2 < b2y && y3 < b2y && y4 < b2y)
          )
            return false;
        } else {
          if (
            (y1 > b2y && y2 > b2y && y3 > b2y && y4 > b2y) ||
            (y1 < b1y && y2 < b1y && y3 < b1y && y4 < b1y)
          )
            return false;
        }
        return true;
      } else if (b1y === b2y) {
        x1 = p1x;
        x2 = p2x;
        x3 = p3x;
        x4 = p4x;
        y1 = y2 = y3 = y4 = b1y;
      } else {
        var a = (b1y - b2y) / (b1x - b2x);
        var ia = 1 / a;
        var t1 = b2x * a - b2y;
        var t2 = 1 / (a + ia);
        x1 = (p1y + t1 + p1x * ia) * t2;
        x2 = (p2y + t1 + p2x * ia) * t2;
        x3 = (p3y + t1 + p3x * ia) * t2;
        x4 = (p4y + t1 + p4x * ia) * t2;
        y1 = p1y + (p1x - x1) * ia;
        y2 = p2y + (p2x - x2) * ia;
        y3 = p3y + (p3x - x3) * ia;
        y4 = p4y + (p4x - x4) * ia;
      }
      if (b1x > b2x) {
        if (
          (x1 > b1x && x2 > b1x && x3 > b1x && x4 > b1x) ||
          (x1 < b2x && x2 < b2x && x3 < b2x && x4 < b2x)
        )
          return false;
      } else {
        if (
          (x1 > b2x && x2 > b2x && x3 > b2x && x4 > b2x) ||
          (x1 < b1x && x2 < b1x && x3 < b1x && x4 < b1x)
        )
          return false;
      }
      return true;
    }
  }, []);

  const classes = useStyles(isMobile)();

  return (
    <div>
      <Modal open={creationOpened} onClose={handleCloseCreationOpened}>
        <Card className={classes.card}>
          <CloseIcon
            className={classes.closeIcon}
            onClick={handleCloseCreationOpened}
          >
            X
          </CloseIcon>
          <Typography
            className={classes.text}
            gutterBottom
            variant="h3"
            component="h2"
          >
            <I18nProvider locale={locale}>
              {translate("creationTitleGeometry")}
            </I18nProvider>
          </Typography>
          <div id="geometryDash"></div>
          <Typography
            className={classes.text}
            gutterBottom
            variant="body1"
            component="p"
          >
            <I18nProvider locale={locale}>
              {translate("creationControlsGeometry")}
            </I18nProvider>
          </Typography>
          <Typography
            className={classes.controls}
            gutterBottom
            variant="body1"
            component="p"
          >
            <I18nProvider locale={locale}>
              {translate("creationBodyGeometry")}
            </I18nProvider>
          </Typography>
        </Card>
      </Modal>
    </div>
  );
}
