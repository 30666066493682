import { LOCALES } from "../locales"

export default {
    [LOCALES.FRENCH]: {
        'leo': 'Léo MOSCILLO',
        'address' : '4 rue Berthelot',
        'ZIPCode' : '21000 Dijon',
        'email' : 'leo.moscillo@gmail.com',

        'menuHome': 'Accueil',
        'menuAbout': 'À Propos',
        'menuCreations': 'Mes Créations',
        'menuContact': 'Contact',
        'menuLanguage': 'Langue',

        'aboutHello': 'Bonjour,',
        'aboutDesc0.1': "je suis Léo, {age} ans et travaillant",
        'aboutDesc0.2': "chez {entreprise} en tant que développeur web",
        'aboutDesc1.1': "je suis Léo, {age} ans et à la recherche",
        'aboutDesc1.2': "d'un travail dans le développement web",
        'aboutDesc2.1': "je suis Léo, {age} ans, auto-entrepreneur",
        'aboutDesc2.2': "et a la recherche de missions de développement web",
        "circleBac" : "Bac",
        "circleDUT" : "DUT",
        "circleLicence" : "Licence",
        "circleSandwich" : "Alternance",

        'modalLearn': "En savoir plus...",
        'modalTitleBac': "Bac",
        'modalDescBac': "En 2018, j'ai obtenu mon baccalauréat STI2D SIN avec mention bien au Lycée Charles Poncet, Cluses (74300).",
        'modalTitleDUT': "DUT MMI",
        'modalDescDUT': "En 2020, j'ai terminer mon DUT MMI (métiers du multimédias et de l'Internet) a Dijon.",
        'modalTitleLicence': "Licence Pro DDIM",
        'modalDescLicence': "En 2021, j'ai effectuer une licence professionel DDIM (Design et Developpement pour l'Internet Mobile) a l'IUT de Dijon ",
        'modalTitleSandwich': "Alternance",
        'modalDescSandwich': "En parallèlle de ma licence, j'ai effectué une alternance au Rectorat de Dijon dans le Pôle EOLE",

        'creationButtonDesign': "Design",
        'creationButtonWeb': "Web",
        'creationButtonGame': "Jeux",
        'creationButtonAll': "Tout",

        'contactResume': 'Trouvez mon CV ici :',
        'contactLegal': 'Mentions légales',
        
        'creationTitleGeometry': 'Geometry Dash',
        'creationControlsGeometry' : '<ESPACE> : SAUT',
        'creationBodyGeometry' : 'Cette copie simplifié de geometry dash a été créer dans le cadre de mon DUT MMI dans le but de savoir utilisé PIXI.js, une librairie permettant de facilité la gestion des canvas',
        
        'creationTitlePong': 'Pong',
        'creationControlsPong' : '<A - Q> : Joueur 1',
        'creationControlsPong2' : '< ↑ - ↓ > : Joueur 2',
        'creationBodyPong' : 'Cette copie simplifié de pong a été créer dans le cadre de mon DUT MMI dans le but de savoir utilisé PIXI.js, une librairie permettant de facilité la gestion des canvas',
        'creationTitleMezig' : 'Mezig',
        'creationSummaryMezig1' : "Application créer dans le cadre de mon Alternance au rectorat de Dijon. Cette application est une sorte de linktree pour le personnel de l'éducation national. Elle est trouvable a l'addresse : ",
        'creationSummaryMezig2' : " ou sur le repertoire : ",
        'creationBodyMezig' : "J'ai developé cette application au sein d'une équipe de 3 developpeur. L'application est basé sur la bibliothèque Svelte et le framework Meteor. L'application comprend notemment une gestion de profil, de base de donnée, une barre de recherche de profil, une auto-détéction des lien de reseaux sociaux etc...",
        'creationTitleLaboite' : 'LaBoite',
        'creationSummaryLaboite1' : "Application créer dans le cadre de mon Alternance au rectorat de Dijon. Cette application est une plateforme liant toutes les applcation a l'attention de l'éducation national. Elle est trouvable a l'addresse : ",
        'creationSummaryLaboite2' : " ou sur le repertoire : ",
        'creationBodyLaboite' : "je me suis occupé de certaines mises a jour de cette application qui a été developpé par les autres membres de l'équipe du Rectorat. Je me suis notemment occupé de la page d'aide et de toute la gestion des photos de profil des utilisateurs. Cette application est une application React, utilisant le framework meteor pour le backend et la bibliothèque Material UI pour le front-end.",
        'creationTitleCCAPhoto' : 'CCAPhoto',
        'creationSummaryCCAPhoto' : "Site web créer dans le cadre de mon projet tuteuré de première année de DUT a l'IUT de Dijon. C'est un site vitrine pour une photographe amateure. Il est trouvable a l'addresse : ",
        'creationBodyCCAPhoto' : "Ce site a été entièrement créer via le CMS Wordpress. Pour ce projet, mon équipe et moi étions directement en contact avec le commanditaire afin d'avoir des échange rapide. Le but de se projet était d'établir une charte graphiques, puis une maquette, puis le produit finis en un temps record tout en effectuant les modification a la demande du commanditaire au fur et a mesure.",
        'creationTitleHandicapsSco' : 'Handicaps & Scolarité',
        'creationSummaryHandicapsSco' : "Ce projet est une application native permettant au parents de personne en situation de handicaps de créer facilement des dossiers administratifs. Elle est téléchargeable a l'adresse : ",
        'creationBodyHandicapsSco' : "Cette application a été réalisé en HTML/CSS et JS ainsi qu'as l'aide de la librairie NWJS. La demande était de créer un moyen de faciliter la création de dossier sans stocké les informations dans une base de données car ce sont des données sensible et médicales. C'est donc pourquoi nous avons opté pour une application téléchargeable. A noter que pour l'instant le seul document pris en charge est la demande de PPS, mais des nouveaux docuement peuvent être introduit facilement.",
        'creationTitleChimere' : 'Chimère',
        'creationSummaryChimere' : "Ce projet a été fait durant mon DUT afin de maitrisé les outils de retouche photo de Adobe Photoshop. Le but était de créer une chimère a l'aide d'image libre de droit et de notre imagination.",
        'creationBodyChimere' : "J'ai choisit de créer une chimère pleine de référence. En premier lieux le nom, qui est un jeu de mot avec le nom d'une autre chimère, le Basilic. Ensuite on retrouvé un crane de cerf en référence a la cryptide du Wendigos, un long cou en référence un Nessie, une queue de serpent pour rappeller une chipocatrix et des ailes d'hippogriffe. Pour ce qui est du fond j'ai voulu représenté une sorte d'ancien bestiaire d'animaux fantastiques, on peux donc voir un numéro de page ainsi que le début d'une deuxième page. Pour l'aspect technique, j'ai principalement utilisé les outils tampon et pièce afin d'arranger les liaisons entre les différent éléments de la chimère.",
        'creationTitleAnamorphose' : 'Anamorphose',
        'creationSummaryAnamorphose' : "Le but de ce projet était de créer une maquette a partir d'une charte graphique établie au préalable pour le site web d'un open Lab de Dijon.",
        'creationBodyAnamorphose' : "Les maquettes sont plutot simple et présente des plante pointant les élément important sur site. Les plantes faisant écho a l'activité principale du laboratoire.",
        'creationSlideAnamorphose1' : "Maquette de la page d'acceuil d'Anamorphose",
        'creationSlideAnamorphose2' : "Maquette de la page de contact d'Anamorphose",
        'creationSlideAnamorphose3' : "Maquette de la page de news d'Anamorphose",
        'creationSlideAnamorphose4' : "Maquette de la page de présentation de l'équipe d'Anamorphose",
        'creationSlideAnamorphose5' : "Maquette de la page de donation d'Anamorphose",
        'creationSlideAnamorphose6' : "Maquette de la page de acceuil d'Anamorphose en version mobile",
        'creationSlideAnamorphose7' : "Maquette de la page de contact d'Anamorphose en version mobile",
        'creationSlideAnamorphose8' : "Maquette de la page de news d'Anamorphose en version mobile",
        'creationTitleDetournement' : 'Détournement',
        'creationSummaryDetournement' : "Dans le cadre de mon DUT j'ai du créer une flyer présentant une des oeuvre d'art de la place Erasme de Dijon. Hors la réprésentation de ces oeuvre étant soumise a des droits d'auteurs, il fallait detourner la représentation de l'oeuvre et pas simplement utilisé une photo. La cible de ces flyers etait des enfants de primaire.",
        'creationBodyDetournement' : "J'ai donc choisit de faire un flyers en deux pages, contenant une petite bande dessiné afin de plaire au enfant. Pour la représention de l'oeuvre, j'ai simplement redésiné une des perspective de l'hommage a Jacque Monod en ajoutant beaucoup de couleur pour ne pas dénaturé avec la bande dessiné.",

        'copy' : 'Le mail a bien été copié.',

        'legalOwner' : 'Propriétaire :',
        'legalHost' : 'Hébergeur :',
        'legalPublication' : 'Gestion des publications :',
        'legalCredit': 'Crédits : ',
        'legalCreditBody': "Sur ce site, preque l'intégralité des contenus on été créer par Léo Moscillo. La réutilisation de ces contenues a des fin commerciales est donc interdite de part la législation francaise. Seul le logo du site, le dessin de la page contact et les icones de réseaux sociaux n'ont pas été créer par Léo. Les dessins on été créer respectivement par ",
        'legalCreditBody2' : ' et par ',
        'legalCreditBody3' : '. Pour ce qui est des icones, elles proviennent de ',
        'legalCreditBody4' : ' et sont libre de droit.',
        'legalPublicationBody' : "Le responsable de la publication de ce site web est Léo Moscillo. Il est responsable de l'intégralité des publications trouvables sur ce site. Les contenus textuelles des pages sont par conséquent sous la protection de la propriété intellectuelle de cette dernière.",
        'legalLink' : 'Liens externes',
        'legalLinkBody' : 'Les liens externes présents sur ce site ne redirigent pas vers des sites sous la responsabilité de Léo Moscillo, toute potentielles activités illégales présentes sur ces derniers ne pourront donc pas être suivi d’une poursuite envers M.Moscillo. Si un lien pointent vers un contenu blessant, offensant ou illégal, merci de prévenir le propriétaire du site dans les plus brefs délai afin qu’elle puisse faire le nécessaire pour supprimer le lien en question.',
        'legalCookies' : 'Utilisations des données personnelles et des cookies',
        'legalCookiesBody' : "Afin d'obtenir un meilleur référencement, ce Portfolio utilise google Analytics. Ce qui implique que des cookies sont récoltés. Des cookies sont des fichiers textes stockés sur votre ordinateur contenant des informations concerannt votre utilisation des sites internet ainsi que votre adressse IP. Ces données recoltés par google seront anonymisé mais si vous vous opposez tout de meme a la collecte de cookie, vous pouvez la désactivés sur votre navigateur.",

        'ovh' : 'OVH',
        'addressOVH': '2 rue Kellermann',
        "ZIPCodeOVH" : "59100 Roubaix",
        "linkOVH" : "https://www.ovh.com/fr/"
    }
}