import React, { useEffect } from "react";
import gsap from "gsap";
import "./Ollicsom.css";

export default function Ollicsom() {
  useEffect(() => {
    gsap
      .timeline({ onComplete: someFunction })
      .to("#curvO", { strokeDashoffset: 0, duration: 0.3 })
      .to("#curvL", { strokeDashoffset: 0, duration: 1 })
      .to("#curvOM", { strokeDashoffset: 0, duration: 0.6 })
      .to("#curvOM", { strokeDashoffset: 930, duration: 0.6, delay: 0.7 })
      .to("#curvL", { strokeDashoffset: 1510, duration: 1 })
      .to("#curvO", { strokeDashoffset: 351, duration: 0.3 })
      .to("#curvO", { delay: 0 });

    function someFunction() {
      window.location += "Portfolio";
    }
  }, []);

  return (
    <div>
      <h1>
        <svg viewBox="0 0 700.2 700.6">
          <defs>
            <clipPath id="websiteClip">
              <path
                id="O"
                className="cls-1"
                d="M64,385.57c0-42.92,24.3-95.25,66.44-95.25,27.05,0,38.62,23.32,38.62,50.56,0,46.26-24.9,101.92-66.06,101.92C76.35,442.8,64,416.34,64,385.57Zm27.64-4.31c0,20.19,6.07,36.65,20.38,36.65,17,0,39.4-25.09,39.4-76.83,0-23.32-8.82-35.48-19.8-35.48C107.9,305.6,91.64,348.72,91.64,381.26Z"
              />
              <path
                id="llics"
                className="cls-1"
                d="M468.54,376.56c7.25.39,13.72-5.1,13.72-16.08,0-12.74-8-19-24.7-19-22.73,0-33.51,14.51-33.51,30.19,0,29.4,32.34,27.63,32.34,42.53a15.67,15.67,0,0,1-15.49,15.68c-6.27,0-12.34-3.92-12.34-12.94,0-4.7,1.56-9.41,4.11-12.35a11,11,0,0,0-7.84-2.74c-9.6,0-15.29,7.06-16.66,15.09-.07.42-.12.84-.17,1.25-7.87,4.56-16.78,7.57-24.52,7.57-10.59,0-18.82-5.68-18.82-21.17,0-28.22,12.74-49.19,23.72-49.19,4.9,0,7.25,2,7.25,5.68a34.84,34.84,0,0,1-3.14,12.74,12,12,0,0,0,7.84,2.75c7.25,0,13.72-5.1,13.72-16.08,0-12.74-7.84-19-23.71-19-29.4,0-50,36.07-50,66.25a50.33,50.33,0,0,0,.62,8.09c-5,4.38-12.5,8.37-19.05,8.37-5.09,0-8.23-3.52-5.88-14.5L328,353c1.57-7.45-2.35-10.78-9.41-10.78-8.23,0-15.29,4.11-22,9.4-3.92,3.14-2.55,9.22,0,10.78,1.76-1.56,2.94-2.35,3.92-2.35,1.76,0,2.55,1.77,2,4.9l-8,38.42h0c-3.72,10.78-19.4,20.38-30.18,20.38-6.86,0-12-3.92-10.58-14.11.19-2.35.58-4.9,1-7.25,25.28-38.61,38-70,38-85.26,0-10.19-6.47-15.88-15.68-15.88-24.65,0-37.74,38.32-46.73,104.18-4.85,9.91-19.23,18.32-29.32,18.32-6.86,0-11.95-3.92-10.58-14.11.2-2.35.59-4.9,1-7.25,25.28-38.61,38-70,38-85.26,0-10.19-6.46-15.88-15.68-15.88-25.48,0-38.61,40.57-47.43,110.15-4.51,4.51-8.43,7.65-14.11,8.82,1.18,6.86,8,10.2,14.31,7.65,2.55,10.39,11,14.89,21,14.89,14,0,31.18-8.45,41.45-20.53.95,14.45,10.2,20.53,21.86,20.53,13.19,0,29.31-7.56,39.71-18.6-.93,14.1,9.23,18.6,18.11,18.6,12.78,0,25.55-8.54,34.71-18.57,5.23,12.66,16.84,18.57,30.36,18.57,11.73,0,24.88-4.48,36.42-12.57,3.73,7.28,12.19,12.57,25.71,12.57,28.81,0,40.18-14.11,40.18-33.12,0-24.89-32.15-20.38-32.15-41.16,0-10.58,6.08-15.88,11.37-15.88,4.71,0,8.82,3.34,8.82,10.59,0,3.92-1.37,8.23-3.33,10.58A15.78,15.78,0,0,0,468.54,376.56ZM278.42,314.23c2.16,0,3.33,1,3.33,4.31,0,6.66-7.25,34.5-24.5,66.44C263.92,343.63,272,314.23,278.42,314.23Zm-63.3,0c2.15,0,3.33,1,3.33,4.31,0,6.66-7.25,34.5-24.5,66.44C200.61,343.63,208.65,314.23,215.12,314.23Z"
              />
              <path
                id="i"
                className="cls-1"
                d="M322.15,327.69c7.64,0,14.11-6.07,15.29-13.72a12.26,12.26,0,0,0-12.55-13.72A15.23,15.23,0,0,0,310,314C308.82,321.62,314.51,327.69,322.15,327.69Z"
              />
              <path
                id="om"
                className="cls-1"
                d="M714.72,414.58c-2.94,7.06-7.45,9.41-11,9.41-3.92,0-6.47-4.31-4.31-14.31l8.23-38.61c5.1-22.93-7.64-28.81-16.46-28.81-11.37,0-21.17,8.42-29.4,20.18.58-13.91-8.43-20.18-16.47-20.18-11.17,0-21,8-29,19.6l2-8.82c1.77-7.45-2.35-10.78-9.4-10.78-8.24,0-15.29,4.11-22,9.4h0c-8.23,6.67-15.48,15.1-23.32,20a17.25,17.25,0,0,0-15.48-9c-11.18,0-15.49,9.8-11.37,20.58,2.15,1.37,5.49,2.16,9,2.35a39,39,0,0,1,1,8c0,17.64-12.54,32.14-22.54,32.14-6.86,0-12.74-6.66-12.74-23.91,0-19.21,11.57-45.47,29.4-45.47a35.87,35.87,0,0,1,12.55,2.15c2.94,1.18,6.27-1,6.66-5.48-5.88-8.82-13.72-11.57-23.52-11.57-29.59,0-50.17,37-50.17,66.64,0,18.43,8.62,34.69,28.81,34.69,23.52,0,51.15-29.59,51.15-59.58a9.11,9.11,0,0,0-.19-2.16c8-5.09,15.68-14.3,20.77-18.62h0c1.77-1.56,2.94-2.35,3.92-2.35,1.77,0,2.75,1.77,2,4.9l-16.07,75.27a17.05,17.05,0,0,0,9.21,2.54c7.06,0,14.51-3.33,16.47-12.34l12-55.86c5.3-6.28,11-10.2,18-10.2,6.46,0,6.07,5.3,4.7,11.57l-13.72,64.29a17,17,0,0,0,9.21,2.54c7.06,0,14.51-3.33,16.47-12.34l12.15-57c5.09-5.49,10.39-9,17-9,6.47,0,6.27,5.3,4.7,11.57l-8.23,39c-4.9,22.93,9,27.83,18.82,27.83,11,0,27.05-9.21,27.05-22.93C720.6,416.54,718.44,414.58,714.72,414.58Z"
              />
            </clipPath>
          </defs>
          <path
            id="curvO"
            className="path"
            clipPath="url(#websiteClip)"
            d="M141.5,413.5s-63,42-65-18,37-99,45-99,37-4,37,25S165.5,376.5,141.5,413.5Z"
            transform="translate(-51.43 -271.31)"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="35"
          />
          <path
            id="curvL"
            className="path"
            clipPath="url(#websiteClip)"
            d="M151.5,424.5s33,20,55-40l22-60s1-30-15-23-23,40-23,40-16,68-11,73,2,17,2,17,31,5,47-9S259,395.69,259,395.69,289.5,342.5,285.5,330.5s10-29-8-25-28,47-28,47-20,66-3,80,56-25,56-25l15-66-23.25,16.41,14.25-3.41-4,78s14,5,40-11,8-17,8-17,8-57,34-55,15,23,15,23l-29-11-17,63s6,17,58,1L425,402s-12.5,32.5-.5,33.5,35-2,38-9,14-22-19-42,14-43,14-43,27.17,7.72,14.08,34.86"
            transform="translate(-51.43 -271.31)"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="35"
          />
          <polygon
            id="curvI"
            clipPath="url(#websiteClip)"
            className="path"
            points="276.07 51.19 264.07 46.19 270.07 37.19 283.16 39.49 276.07 51.19"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="35"
          />
          <path
            id="curvOM"
            clipPath="url(#websiteClip)"
            className="path"
            d="M560.5,353.5s-41-17-61,39,26,41,26,41,57-27,21-58,20,6,20,6,38-53,43-30S583,448,583,448l31.5-81.5s40-39,35,0S633,443,633,443l22.5-76.5s54-35,41-5-8,70-8,70,13,15,32-14"
            transform="translate(-51.43 -271.31)"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="35"
          />
        </svg>
      </h1>
    </div>
  );
}
