import React, { useEffect } from "react";
import gsap from "gsap";
import MainTitle from "../svg/MainTitle";
import "./sections.css";
import "./Home.css";
import "../homeCanvas";
import { SummonSquare } from "../homeCanvas";

export default function Home() {
  useEffect(() => {
    gsap
      .timeline({})
      .to("#Trace1", {
        strokeDashoffset: 0,
        ease: "power2.easeInOut",
        duration: 1.5,
      })
      .to("#Trace1Ombre", {
        strokeDashoffset: 0,
        ease: "power2.easeInOut",
        duration: 1.5,
        delay: -1.5,
      })
      .to("#Trace2", {
        strokeDashoffset: 0,
        ease: "power2.easeInOut",
        duration: 2,
        delay: -1,
      })
      .to("#Trace2Ombre", {
        strokeDashoffset: 0,
        ease: "power2.easeInOut",
        duration: 2,
        delay: -1,
      })
      .to("#T1", {
        opacity: 1,
        ease: "power2.easeInOut",
        duration: 2,
        delay: -3,
      })
      .to("#T1Ombre", {
        opacity: 0.6,
        ease: "power2.easeInOut",
        duration: 2,
        delay: -3,
      })
      .to("#T2", {
        opacity: 1,
        ease: "power2.easeInOut",
        duration: 1.5,
        delay: -3,
      })
      .to("#T2Ombre", {
        opacity: 0.6,
        ease: "power2.easeInOut",
        duration: 1.5,
        delay: -1,
      })
      .to("#AcceuilSup", {
        opacity: 1,
        ease: "power2.easeInOut",
        duration: 1,
        delay: -1.5,
      })
      .to("#AcceuilInf", {
        opacity: 1,
        ease: "power2.easeInOut",
        duration: 1.5,
        delay: -1.5,
      });
  });

  return (
    <div id="home" className="section">
      <MainTitle />
    </div>
  );
}
