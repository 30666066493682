import React, { useEffect } from "react";
import gsap from "gsap";
import { I18nProvider, LOCALES } from "../i18n";
import CloseIcon from "@material-ui/icons/Close";
import { HashLink } from "react-router-hash-link";

import translate from "../i18n/translate";

import $ from "jquery";

import "./Menu.css";
import "../fonts/font.css";

export default function Menu({ isMobile, locale, setLocale }) {
  $(document).ready(function () {
    $("main").on("scroll", () => {
      switch ($("main").scrollTop()) {
        case 0:
          $("#ScrollLine>a>div.active").toggleClass("active");
          $("#ScrollLine1>div").toggleClass("active");
          break;
        case window.innerHeight * 1:
          $("#ScrollLine>a>div.active").toggleClass("active");
          $("#ScrollLine2>div").toggleClass("active");
          break;
        case window.innerHeight * 2:
          $("#ScrollLine>a>div.active").toggleClass("active");
          $("#ScrollLine3>div").toggleClass("active");
          break;
        case window.innerHeight * 3:
          $("#ScrollLine>a>div.active").toggleClass("active");
          $("#ScrollLine4>div").toggleClass("active");
          break;
        default:
          break;
      }
    });
  });

  function hoverNavIn() {
    if (window.innerWidth >= 768) {
      this.style.backgroundColor = "white";
    }
  }
  function hoverNavOut() {
    if (window.innerWidth >= 768) {
      this.style.backgroundColor = "rgb(77, 168, 163)";
    }
  }
  function menuColor() {
    if ($("nav").hasClass("active")) {
      $("nav").css("backgroundColor", "white");
    } else {
      $("nav").css("backgroundColor", "rgb(77, 168, 163)");
    }
  }

  function changeLang(displayed) {
    switch (displayed) {
      case "menu":
        if (window.innerWidth > 768) {
          $("#availableLanguages").css("display", "grid");
          gsap
            .timeline({
              onComplete: () => {
                $("#linkMenu").css("display", "none");
              },
            })
            .to("#linkMenu", {
              opacity: 0,
              ease: "power2.easeInOut",
              duration: 0.3,
            })
            .to("#availableLanguages", {
              ease: "power2.easeInOut",
              opacity: 1,
              duration: 0.3,
            });
        } else {
          $("#availableLanguages").css("display", "grid");
          gsap
            .timeline({
              onComplete: () => {
                $("#menuItem4").css("display", "none");
              },
            })
            .to("#menuItem4", {
              opacity: 0,
              ease: "power2.easeInOut",
              duration: 0.3,
            })
            .to("#availableLanguages", {
              ease: "power2.easeInOut",
              opacity: 1,
              duration: 0.3,
            });
        }
        break;

      case "lang":
        if (window.innerWidth > 768) {
          $("#linkMenu").css("display", "flex");
          gsap
            .timeline({
              onComplete: () => {
                $("#availableLanguages").css("display", "none");
              },
            })
            .to("#availableLanguages", {
              opacity: 0,
              ease: "power2.easeInOut",
              duration: 0.3,
            })
            .to("#linkMenu", {
              ease: "power2.easeInOut",
              opacity: 1,
              duration: 0.3,
            });
        } else {
          $("#menuItem4").css("display", "grid");
          gsap
            .timeline({
              onComplete: () => {
                $("#availableLanguages").css("display", "none");
              },
            })
            .to("#availableLanguages", {
              opacity: 0,
              ease: "power2.easeInOut",
              duration: 0.3,
            })
            .to("#menuItem4", {
              ease: "power2.easeInOut",
              opacity: 1,
              duration: 0.3,
            });
        }
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    let navMouseLeave;

    gsap
      .timeline({})
      .to("#menuSVG", { width: "100%", duration: 0.7 })
      .to("#menu", {
        backgroundColor: "rgb(77, 168, 163)",
        duration: 0.5,
        delay: -0.6,
      })
      .fromTo(
        "#divLogo>*>img",
        { scale: 0, rotate: "60deg" },
        { scale: 1, rotate: "0deg", duration: 0.9 }
      )
      .to("#menuItem1>a", { translateY: "0", delay: 0.5, duration: 0.3 })
      .to("#menuItem2>a", { translateY: "0", delay: -0.2, duration: 0.3 })
      .to("#menuItem3>a", { translateY: "0", delay: -0.2, duration: 0.3 })
      .to("#menuItem4>a", { translateY: "0", delay: -0.2, duration: 0.3 })
      .to("#name", { translateX: "0", delay: 0.8, duration: 0.3 })
      .to("#ScrollLine", {
        transform: "translate(0%, -9.5vh)",
        ease: "power2.easeInOut",
        duration: 0.3,
        delay: -2,
      });

    $("nav").on("mouseenter", hoverNavIn).on("mouseleave", hoverNavOut);

    if (window.innerWidth < 768) {
      $("nav").on("click", () => {
        $("nav").addClass("active");
        menuColor();
      });

      $(document).mouseup(function (e) {
        var container = $("nav");

        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          $("nav").removeClass("active");
          changeLang("lang");
          menuColor();
        }
      });
    }

    $("nav").on("mouseleave", () => {
      if (window.innerWidth >= 768) {
        navMouseLeave = setTimeout(function () {
          changeLang("lang");
        }, 2500);
      }
    });
    $("nav").on("mouseenter", () => {
      clearTimeout(navMouseLeave);
    });
  }, []);

  return (
    <header>
      <nav id="menu">
        <div id="divLogo">
          {isMobile ? (
            <div>
              <img alt="logo" src="./logo.png"></img>
            </div>
          ) : (
            <HashLink id="0" to="/Portfolio#home">
              <img alt="logo" src="./logo.png"></img>
            </HashLink>
          )}
          <div id="divName">
            <p id="name">
              <I18nProvider locale={locale}>{translate("leo")}</I18nProvider>
            </p>
          </div>
        </div>
        <div id="linkMenu" className="active">
          {isMobile ? (
            <CloseIcon
              id="closeMenu"
              onClick={() => {
                $("nav").removeClass("active");
                menuColor();
                changeLang("lang");
              }}
            />
          ) : (
            ""
          )}
          {isMobile ? (
            <div className="menuItem" id="menuItemHome">
              <HashLink id="0" to="/Portfolio#home">
                <I18nProvider locale={locale}>
                  {translate("menuHome")}
                </I18nProvider>
              </HashLink>
            </div>
          ) : (
            ""
          )}
          <div className="menuItem" id="menuItem1">
            <HashLink id="1" to="/Portfolio#about">
              <I18nProvider locale={locale}>
                {translate("menuAbout")}
              </I18nProvider>
            </HashLink>
          </div>
          <div className="menuItem" id="menuItem2">
            <HashLink id="2" to="/Portfolio#creations">
              <I18nProvider locale={locale}>
                {translate("menuCreations")}
              </I18nProvider>
            </HashLink>
          </div>
          <div className="menuItem" id="menuItem3">
            <HashLink id="3" to="/Portfolio#contact">
              <I18nProvider locale={locale}>
                {translate("menuContact")}
              </I18nProvider>
            </HashLink>
          </div>
          <div
            className="menuItem"
            id="menuItem4"
            onClick={() => {
              changeLang("menu");
            }}
          >
            <a id="4">
              <I18nProvider locale={locale}>
                {translate("menuLanguage")}
              </I18nProvider>
            </a>
          </div>
        </div>
        <div id="availableLanguages">
          <p
            id="fr"
            onClick={() => {
              setLocale(LOCALES.FRENCH);
              changeLang("lang");
            }}
          >
            Français
            {locale === "fr-fr" ? " ✓" : ""}
          </p>
          <p
            id="en"
            onClick={() => {
              setLocale(LOCALES.ENGLISH);
              changeLang("lang");
            }}
          >
            English
            {locale === "en-us" ? " ✓" : ""}
          </p>
        </div>

        <svg id="menuSVG" viewBox="0 0 1920 2.44">
          <path
            id="menuPath"
            d="M-117,133s2008-1,2037,0"
            transform="translate(117 -131.56)"
          />
        </svg>
      </nav>

      <div id="ScrollLine">
        <HashLink
          to="/Portfolio#home"
          id="ScrollLine1"
          onClick={() => {
            $("#ScrollLine>a>div.active").toggleClass("active");
            $("#ScrollLine1>div").toggleClass("active");
          }}
        >
          <div className="ScrollLine active" />
        </HashLink>
        <HashLink
          to="/Portfolio#about"
          id="ScrollLine2"
          onClick={() => {
            $("#ScrollLine>a>div.active").toggleClass("active");
            $("#ScrollLine2>div").toggleClass("active");
          }}
        >
          <div className="ScrollLine" />
        </HashLink>
        <HashLink
          to="/Portfolio#creations"
          id="ScrollLine3"
          onClick={() => {
            $("#ScrollLine>a>div.active").toggleClass("active");
            $("#ScrollLine3>div").toggleClass("active");
          }}
        >
          <div className="ScrollLine" />
        </HashLink>
        <HashLink
          to="/Portfolio#contact"
          id="ScrollLine4"
          onClick={() => {
            $("#ScrollLine>a>div.active").toggleClass("active");
            $("#ScrollLine4>div").toggleClass("active");
          }}
        >
          <div className="ScrollLine" />
        </HashLink>
      </div>
    </header>
  );
}
